import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";

function AdminTableFilters(props) {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div>
      <InputGroup className="mb-3">
        {props.search && (
          <Button
            variant="outline-secondary"
            onClick={() => props.search(searchTerm)}
          >
            <BsSearch />
          </Button>
        )}
        {props.search && (
          <Form.Control
            placeholder="Search"
            onChange={handleChange}
            value={searchTerm}
          />
        )}
        {props.options && props.options[0] && (
          <Form.Select onChange={props.handleSelectChange}>
            {props.options.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </Form.Select>
        )}
      </InputGroup>
    </div>
  );
}

export default AdminTableFilters;
