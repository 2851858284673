import { useEffect, useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { BsPencilSquare} from "react-icons/bs";
import PhoneNumberInput from '../PhoneNumberInput';
import Axios from '../axios/AxiosOnboarding';
import { toast } from 'react-toastify';

const MerchantUpdateModal = (props) => {
    const [show, setShow] = useState(false);
    const { merchant } = props
    // console.log( merchant )
    const merchant_id = ''
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [displayName, setDisplayName] = useState(merchant.display_name ? merchant.display_name : '');
    const [supportEmail, setSupportEmail] = useState(merchant.support_email ? merchant.support_email :'');
    const [statementDescriptor, setStatementDescriptor] = useState(merchant.statement_descriptor ? merchant.statement_descriptor : '');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [status, setStatus ] = useState(merchant.status ? merchant.status : '');
    useEffect(()=>{
      if(show){
        setDisplayName(merchant.display_name ? merchant.display_name : '');
        setSupportEmail(merchant.support_email ? merchant.support_email :'')
        setStatementDescriptor(merchant.statement_descriptor ? merchant.statement_descriptor : '')
        // setPhoneNumber(merchant.phone_number ? merchant.phone_number : '')
        setStatus(merchant.status ? merchant.status : '')
      }
    },[show])

    const [loading , setloading] = useState(false)

        // update_merchant_route(merchant_id)
        const handleUpdate = () => {
          setloading(true)
          Axios.put(`/onboarding-team/update-merchant/${merchant.id}`, 
            {statement_descriptor:statementDescriptor , 
            phone_number:phoneNumber , 
            support_email:supportEmail , 
            display_name:displayName,
            status
          })

          .then((Response)=>{
            // console.log( Response );
            props.setReload( current => !current)
            toast.success('Data Updated')
          })
          .catch((error) => {
            console.error('Error updating Merchant:', error);
            toast.error('Something went wrong')
          })
          .finally( () => {
            setloading(false)
            handleClose()
          });
        }

  return (
    <div>
      <Button title='Update Merchant Data' variant="light" style={{ color: 'rgb(98,77,227)' }} onClick={handleShow}>
        <BsPencilSquare />
      </Button>

      <Modal show={show} onHide={handleClose} style={{marginTop:'8rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>Update {merchant.display_name} Data </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form>
                <Form.Group controlId="status" className='mb-3'>
                    <Form.Label>Status : <span style={{fontSize:'12px', color:'green'}}>{merchant.status}</span></Form.Label>
                    <Form.Select
                    as="select" 
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  
                    >
                    <option value="">Select Status</option>
                    <option value="completed">Completed</option>
                    <option value="pending_verification">Pending Verification</option>
                    <option value="waiting_information">Waiting Information</option>
                    <option value="request_correction">Request Correction</option>
                    <option value="rejected">Rejected</option>
                   </Form.Select>
                </Form.Group>

                <Form.Group controlId="displayName" className='mb-3'>
                {/* <Form.Label>Display Name</Form.Label> */}
                <Form.Control
                type="text"
                value={displayName}
                placeholder='Display Name'
                onChange={(e) => setDisplayName(e.target.value)}
                />
                </Form.Group>

                <Form.Group controlId="statementDescriptor" className='mb-3'>
                {/* <Form.Label>Statement Descriptor</Form.Label> */}
                <Form.Control
                type="text"
                value={statementDescriptor}
                placeholder='Statement Descriptor'
                onChange={(e) => setStatementDescriptor(e.target.value)}
                />
                </Form.Group>

                <Form.Group controlId="supportEmail" className='mb-3'>
                    {/* <Form.Label>Support Email</Form.Label> */}
                    <Form.Control
                    type="email"
                    placeholder='support Email'
                    value={ supportEmail}
                    onChange={(e) => setSupportEmail(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="phoneNumber" className='mb-3'>
                <Form.Label style={{fontSize:'12px', color:'green'}}>Please Enter Phone Number</Form.Label>
                  <PhoneNumberInput 
                  phoneNumber={phoneNumber}
                  phone_number = {merchant.phone_number}
                  setPhoneNumber={setPhoneNumber}/>

                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer style={{justifyContent:'center',justifyItems:'center'}}>
          <Button style={{ color: 'white', backgroundColor:'rgb(98,77,227)', border:'none', width:'80%'}} onClick={handleUpdate} disabled={loading}>
          {loading ? <Spinner animation='border' variant='light' size='sm'/> : <><BsPencilSquare /> Update</>}
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  )
}

export default MerchantUpdateModal
