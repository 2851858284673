import React from "react";
import { ToggleButton } from "react-bootstrap";

const TabsToggleButton = ({radioValue, disableRadio, setRadioValue, text, targetValue}) => {
  return (
    <ToggleButton
      style={
        radioValue === targetValue
          ? {
              borderBottom: "2px solid #008CBA",
              margin: "0 5px 2.5px 1px",
              color: "#008CBA",
            }
          : {
              margin: "0 5px 2.5px 1px",
            }
      }
      size="sm"
      type="radio"
      disabled={disableRadio}
      variant={radioValue === targetValue ? "" : ""}
      onClick={(e) => setRadioValue(targetValue)}
    >
      {text}
    </ToggleButton>
  );
};

export default TabsToggleButton;
