
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Axios from '../components/axios/AxiosOnboarding';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.core.css";
import 'react-quill/dist/quill.snow.css';


function Email() {
  const [toEmailError, setToEmailError] = useState('');
  const [ccEmailError, setCcEmailError] = useState('');
  const [bccEmailError, setBccEmailError] = useState('');
  const [toEmails, setToEmails] = useState([]);
  const [ccEmails, setCcEmails] = useState([]);
  const [bccEmails, setBccEmails] = useState([]);
  const [writeSubject, setWriteSubject] = useState('');
  const [selectOption, setSelectOption] = useState('');
  const [encodedFile, setEncodedFile] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const template = `
  
  <head>
  <div style="display: flex; align-items: center; justify-content: center;">
  <h1 style=" font-weight: 250;">Fintesa</h1>
  </div>
  <br/>
  <p style="font-size: 20px;">Dear Merchant,</p>
  <p>Email content ...</p>
  <br/>

  <p style=" text-decoration: none;"> Regards,</p>
  <p style=" text-decoration: none;">Fintesa Team</p>
  <br/>
  <img src="https://media.licdn.com/dms/image/C4D0BAQEl53oeMmxVkw/company-logo_200_200/0/1655145446193?e=2147483647&v=beta&t=bM1gNu-Z5egSgiQu5mYvGsjjV7vxkslbck9eK1dyFXs " width="45px" height="45px"   alt="Fintesa logo" />
 
  <br/>
  <div style="display: flex; align-items: center; justify-content: center;">
  <p style="color: rgba(0, 0, 0, 0.8)">
  Powered by |
  <a target="_blank" href="https://fintesa.co/" style="color: rgba(0, 0, 0, 0.8); text-decoration: none;">Fintesa</a>
  <a target="_blank" href="https://fintesa.co/terms-and-conditions" style="color: rgba(0, 0, 0, 0.8); text-decoration: none;">Terms</a>
  </p>
   </div>
  </head>
`;
  const [emailContent, setEmailContent] = useState(template);
  const modules = {
    toolbar: [
      [{ font: [] }],
      [{ header: [1, 2, 3] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      [{ align: [] }],
      ["link", "image", "video"],
      ["clean"],
    ],
  };
  const handleEmailContentChange = (value) => {
    setEmailContent(value);
  };

  const convertTimeZone = (date, timeZone) => {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
        timeZone,
      })
    );
  };
  const todayDate = convertTimeZone(new Date(), 'America/Los_Angeles').toISOString();

  const isValidEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(email);
  };

  const handleToEmailChange = (e) => {
    const inputValue = e.target.value;
    setToEmails(inputValue.split(',').map((email) => email.trim()));

    // Validate each email in the list
    const invalidEmails = inputValue
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '' && !isValidEmail(email));

    if (invalidEmails.length > 0) {
      setToEmailError(
        <>
          <span style={{ color: 'red' }}>Invalid email address</span>
        </>
      );
    } else {
      setToEmailError('');
    }
  };

  const handleCcEmailChange = (e) => {
    const inputValue = e.target.value;
    setCcEmails(inputValue.split(',').map((email) => email.trim()));

    // Validate each email in the list
    const invalidEmails = inputValue
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '' && !isValidEmail(email));

    if (invalidEmails.length > 0) {
      setCcEmailError(
        <>
          <span style={{ color: 'red' }}>Invalid email address</span>
        </>
      );
    } else {
      setCcEmailError('');
    }
  };

  const handleBccEmailChange = (e) => {
    const inputValue = e.target.value;
    setBccEmails(inputValue.split(',').map((email) => email.trim()));

    // Validate each email in the list
    const invalidEmails = inputValue
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '' && !isValidEmail(email));

    if (invalidEmails.length > 0) {
      setBccEmailError(
        <>
          <span style={{ color: 'red' }}>Invalid email address</span>
        </>
      );
    } else {
      setBccEmailError('');
    }
  };



  const handleFileInputChange = (e) => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 0) {
      const totalSize = Array.from(selectedFiles).reduce(
        (acc, file) => acc + file.size,
        0
      );

      // Check if the total size of selected files is less than or equal to 20MB (20 * 1024 * 1024 bytes)
      if (totalSize <= 20 * 1024 * 1024) {
        const readers = [];

        for (const file of selectedFiles) {
          const reader = new FileReader();

          reader.onload = (event) => {
            const base64String = btoa(
              new Uint8Array(event.target.result).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ''
              )
            );
            const attachmentFiles = {
              file_content: base64String,
              file_type: file.type,
              file_name: file.name,
              content_id: file.name
            };
            encodedFile.push(attachmentFiles);
          };

          reader.readAsArrayBuffer(file);
          readers.push(reader);
        }
      } else {
        // Display a message to upload to Google Drive
        toast.error('Total file size exceeds 20MB. Consider uploading to Google Drive.');
      }
      // Enable or disable the "Submit" button based on the total size of attachments
      const submitButton = document.querySelector('[name="submit"]');
      if (totalSize > 20 * 1024 * 1024) {
        submitButton.disabled = true;
      } else {
        submitButton.disabled = false;
      }

    }
  };
  useEffect(() => {
    if (!isSending) {
      setEncodedFile([]);
    }
  }, [isSending]);

  function SendEmail(event) {
    event.preventDefault();

    if (toEmailError || ccEmailError || bccEmailError) {
      toast.error('Please fix the email validation errors before sending.');
      return;
    }
    // Check if any of the email addresses in "To," "Cc," and "Bcc" are the same
    const allEmails = [...toEmails, ...ccEmails, ...bccEmails];
    const uniqueEmails = [...new Set(allEmails)];
    if (uniqueEmails.length !== allEmails.length) {
      toast.error('Email addresses should be unique.!');
      return;
    }

    setIsSending(true);
    Axios.post(`/accounts/admin-send-emails`, {
      to_emails: selectOption ? [] : toEmails,
      cc_emails: ccEmails,
      bcc_emails: bccEmails,
      subject: writeSubject,
      html_content: emailContent,
      attachment: encodedFile,
      select_option: selectOption,
      todayDate: todayDate,
    })
      .then((res) => {
        console.log('post data res.data', res.data);
        setSelectOption(res.data);
        // Reset the form by clearing the state variables
        setToEmails([]);
        setCcEmails([]);
        setBccEmails([]);
        setWriteSubject('');
        setSelectOption('');
        setEmailContent(template);


        if (res.data) {
          toast.success('Email sent successfully!');

          // After success, set isSending back to false
          setIsSending(false);

        } else {
          toast.error('Failed to send email. Please try again.');
          // After success, set isSending back to false
          setIsSending(false);
        }
      })
      .catch((err) => {
        toast.error('Something went wrong');
        setIsSending(false); // Handle error and reset isSending
      });
  }

  return (
    <>
      <h2>Send Email</h2>
      <div className='email-grids'
        style={{ marginTop: "40px", backgroundColor: "#fff", padding: "2rem", borderRadius: "1rem", width: "99%" }}
      >
        <Form style={{ width: '70%' }} onSubmit={SendEmail}>
          <Form.Label>Send to</Form.Label>

          <select
            id="dropdown-basic-button"
            title="Select_Send_to"
            style={{
              marginLeft: '5px',
              marginBottom: '10px',
              border: '1px solid #dee2e6',
              borderRadius: '0.375rem',
            }}
            onChange={(e) => {
              setSelectOption(e.target.value);
            }}
            value={selectOption}
          >
            <option value="">Select email</option>
            <option value="all">All</option>
            <option value="with_charges">With charges</option>
            <option value="without_charges">Without charges</option>
          </select>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>To</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="to_emails"
              value={toEmails.join(', ')}
              onChange={handleToEmailChange}
              disabled={selectOption !== ''}
              required

            />
            {toEmailError && <div className="error">{toEmailError}</div>}
            <br />
            <Form.Label>Cc</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="cc_emails"
              value={ccEmails.join(', ')}
              onChange={handleCcEmailChange}
            />
            {ccEmailError && <div className="error">{ccEmailError}</div>}
            <br />
            <Form.Label>Bcc</Form.Label>
            <Form.Control
              type="text"
              placeholder="name@example.com"
              name="bcc_emails"
              value={bccEmails.join(', ')}
              onChange={handleBccEmailChange}
            />
            {bccEmailError && <div className="error">{bccEmailError}</div>}
            <br />
          </Form.Group>
          <Form.Label>Subject</Form.Label>
          <Form.Control
            type="text"
            placeholder="Subject"
            name="subject"
            value={writeSubject}
            onChange={(e) => setWriteSubject(e.target.value)}
            required
          />
          <br />
          <ReactQuill
            theme="snow"
            modules={modules}
            value={emailContent}
            onChange={handleEmailContentChange}
          />
          <br />


          <br />
          <Form.Control
            type="file"
            accept='image/*,video/*,audio/*,.pdf,.docx,.txt'
            multiple
            onChange={handleFileInputChange}
            key={isSending ? 'reset' : 'normal'}
          />
          <br />

          <button
            as="input"
            type="submit"
            name="submit"

            style={{ backgroundColor: '#635BFF', border: 'none', color: 'white', padding: '3px', width: '95px', borderRadius: '8px' }}
            disabled={isSending} >
            {isSending ? <Spinner animation="border" size="sm" role="status" aria-hidden="true" /> : 'Submit'}

          </button>

        </Form >
        <div style={{ width: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img style={{ width: "90%", height: 'auto' }} src="/img/email.png" alt="email" />
        </div>

      </div>
    </>

  );
}

export default Email;


