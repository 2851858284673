import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, ButtonGroup } from "react-bootstrap";
import TabsToggleButton from "../buttons/TabsToggleButton";

const ProductsHeader = () => {
  const [radioValue, setRadioValue] = useState("one-time");
  const [disableRadio, setDisableRadio] = useState(false);
  const navigate = useNavigate();
  const createClickHandler = () => {
    navigate("/create-product");
  }
  return (
    <div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
      <ButtonGroup className="mb-3" style={{ marginTop: "20px" }}>
        <TabsToggleButton text="One-Time" disableRadio={disableRadio} radioValue={radioValue} setRadioValue={setRadioValue} targetValue="one-time" />
        <TabsToggleButton text="Subscription" disableRadio={disableRadio} radioValue={radioValue} setRadioValue={setRadioValue} targetValue="recurring" />
      </ButtonGroup>
      <Button onClick={createClickHandler}>
        Create Product
      </Button>
    </div>
  );
};

export default ProductsHeader;
