import React from 'react'
import './Customer.css'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgressCustomerBar = (props) => {

// console.log(props)

  return (
    <div style={{marginTop:'4rem',display:'flex',placeContent:'center', alignItems:'center', justifyContent:'center'}}>
      {props.isLoading && 
        <Skeleton height={150} width={150} circle />
        }
        {!props.isLoading && <>
        <CircularProgressbar className="circularBar" value={props.merchantsThisMonth} text={`${props.merchantsThisMonth}`} />
        </>}
    </div>
  )
}

export default CircularProgressCustomerBar