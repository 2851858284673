import React from 'react'
import AdminMerchantData from '../components/AdminMerchantData'

const Merchant = () => {
  return (
    <div>
      <h3 className='header'>Merchant</h3>
      <AdminMerchantData />
    </div>
  )
}

export default Merchant