import React, { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import Axios from '../components/axios/AxiosOnboarding';
import AuthContext from '../context/AuthContext';
import { toast } from 'react-toastify';
import { Table, Form } from 'react-bootstrap';
import PaginationNav from '../components/PaginationNav';
import { BsCaretDown } from "react-icons/bs";
import '../components/Tables.css'


function MessagesList() {
  const [messages, setMessages] = useState([]);
  const [isLoading, setLoading] = useState(false); // Loading in Button
  
  const [ currentPage , setCurrentPage ] = useState(1)
  const [ recordsPerPage, setRecordsPerPage ] = useState(15)
  const indexOfLastRow = currentPage * recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - recordsPerPage; // first index in each page 
  const recordsMessages = messages.slice(indexOfFirstRow, indexOfLastRow); 
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  const getMessagesData = async () => {
    try{
      const responce = await Axios.get(`/messages-list/`);
      setMessages(responce.data);
      setLoading(false);
      console.log(responce.data)
      
    }catch (error){
      console.error('Error fetching Messages Data:', error);
      toast.error('Error fetching Messages Data:', error)
    }
  }
  useEffect(() => {
    setLoading(true);
    getMessagesData()
  }, []);
  return (
    <div 
    // className="text-center" 
    style={{width:'99%', marginTop:'2rem', marginBottom:'2rem'}}
    >
    <div className="flex justify-center my-10" 
    style={{marginTop:'2rem',
    backgroundColor: "white",
    padding: "5rem",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    }}>
      <Form.Label style={{color:'gray', fontSize:'12px'}}>No.Messages / page <BsCaretDown /> </Form.Label>
              <Form.Select
                id="outlined-basic"
                variant="outlined"
                style={{width:'10%'}}
                as="select"
                label="Filter"
                value={recordsPerPage} 
                onChange={(e)=> setRecordsPerPage(e.target.value)}
              >
                    <option value={5} id="options">{5}</option>
                    <option value={10} id="options">{10}</option>
                    <option value={15} id="options">{15}</option>
                    <option value={20} id="options">{20}</option>
                    <option value={25} id="options">{25}</option>
              </Form.Select>
      <div style={{ overflowX: 'auto' }}>
      <Table >
        <thead>
          <tr style={{color:'rgb(98,77,227)'}}>
            <th style={{color:'rgb(98,77,227)'}}>From</th>
            <th style={{color:'rgb(98,77,227)'}}>To</th>
            <th style={{color:'rgb(98,77,227)'}}>Body</th>
            <th style={{color:'rgb(98,77,227)'}}>Date Sent</th>
            <th style={{color:'rgb(98,77,227)'}}>Status</th>
            <th style={{color:'rgb(98,77,227)'}}>Cost</th>
            {/* <th style={{color:'rgb(98,77,227)'}}>Error Message</th> */}
          </tr>
        </thead>
        <tbody>
          {messages && messages.length >0 ? (
          recordsMessages.map((message, index) => (
            <tr key={index} className='table-row-hover' style={{fontSize:'14px'}}>
              <td >{message.from}</td>
              <td >{message.to}</td>
              <td>{message.body}</td>
              <td >{message.date_sent}</td>
              <td >{message.status}</td>
              <td >{`${message.price} ${message.price_unit}`}</td>
              {/* <td >{message.error_message}</td> */}
            </tr>
          ))
          ):(
          <tr> 
            <td colSpan="6" className="noData">No Messages</td>
          </tr>
          )}
        </tbody>
      </Table>
      </div>

      {/* Pagination Nav */}
      {!isLoading&&
      <div style={{padding:'2rem', overflowX:'auto'}}>  
      <PaginationNav
      recordsPerPage = {recordsPerPage}
      totalRows = {messages.length} 
      currentPage={currentPage}
      paginate= {paginate}
       /></div>}
    </div>
  </div>
);
}


 

export default MessagesList
