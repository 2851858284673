import React, { useContext, useEffect, useState } from 'react'
import './Sidebar.css'
import { Link } from "react-router-dom";
import Logo from './fintesaLogo-removebg.png'
import { BsBoxSeam, BsBriefcase, BsPeople, BsFillInboxesFill, BsList, BsCashStack, BsSendFill, BsEnvelopePaperFill, BsFillEnvelopePaperFill } from "react-icons/bs";
import AuthContext, { AuthProvider } from '../context/AuthContext';
import { getCookie } from 'typescript-cookie';

const Sidebar = (props) => {
    const [pagePath, setPagePath] = useState(window.location.pathname);

    let [authTokens, setAuthTokens] = useState(() =>  getCookie('jwt_authtoken') ? (getCookie('jwt_authtoken')) : null);
    let { role } = useContext(AuthContext)
    
    useEffect(()=>{
        closeSideBar()
    },[pagePath])
    const closeSideBar=()=>{
        if (window.innerWidth <= 1024){
          props.toggleSidebar()
        }
      }

    return (

        <div >
            {authTokens && role !== "financial" ?
            <div>
                <div id='Sidebar' className={`${props.setIsSidebarOpen ? "open" : "close"}`}>
                    <div className='logo'>
                        <img src={Logo} alt="logo" />
                        <span style={{ fontSize: '18px', marginTop: '0.5rem' }}>Fintesa Team</span>

                    </div>

                    <div className='menu'>
                        <Link className='Link' to='/' onClick={() => {setPagePath("/")}}>
                            <div className={`menuItem ${pagePath === "/" ? 'active' : ''} ${props.isSidebarOpen ? 'close' : 'open'}`}>
                                <div className='menuIcon'><BsBoxSeam /></div>
                                <span>Dashboard</span>
                            </div>
                        </Link>
                        <Link className='Link' to="/merchant" onClick={() => {setPagePath("/merchant")}}>
                            <div className={`menuItem ${pagePath === "/merchant" ? 'active' : ''}`}>
                                <div><BsBriefcase /></div>
                                <span>Merchant</span>
                            </div>
                        </Link>
                        <Link className='Link' to='/owner' onClick={() => {setPagePath("/owner")}}>
                            <div className={`menuItem ${pagePath === "/owner" ? 'active' : ''}`}>
                                <div><BsPeople /></div>
                                <span>Owner</span>
                            </div>
                        </Link>
                        <Link className='Link' to='/payout' onClick={() => {setPagePath("/payout")}}>
                            <div className={`menuItem ${pagePath === "/payout" ? 'active' : ''}`}>
                                <div><BsCashStack /></div>
                                <span>Payout</span>
                            </div>
                        </Link>


                        <Link className='Link' to='/email' onClick={() => {setPagePath("/email")}}>
                            <div className={`menuItem ${pagePath === "/email" ? 'active' : ''}`}>
                                <div><BsFillEnvelopePaperFill /></div>
                                <span>Email</span>
                            </div>
                        </Link>
                        <Link className='Link' to='/products' onClick={() => setPagePath("/products")}>
                            <div className={`menuItem ${pagePath === "/products" ? 'active' : ''}`}>
                                <div><BsFillInboxesFill /></div>
                                <span>Products</span>
                            </div>
                        </Link>
                    </div>

                </div>
                </div>
                :
                <div id='Sidebar' className={`${props.setIsSidebarOpen ? "open" : "close"}`}>
                    <div className='logo'>
                        <img src={Logo} alt="logo" />
                        <span style={{ fontSize: '18px', marginTop: '0.5rem' }}>Fintesa Team</span>

                    </div>


                    <div className='menu'>

                        <Link className='Link' to="/sms" onClick={() => setPagePath("/sms")}>
                            <div className={`menuItem ${pagePath === "/sms" ? 'active' : ''}`}>
                                <div><BsSendFill /></div>
                                <span>Send Message </span>
                            </div>
                        </Link>
                        <Link className='Link' to='/messages' onClick={() => setPagePath("/messages")}>
                            <div className={`menuItem ${pagePath === "/messages" ? 'active' : ''}`}>
                                <div><BsEnvelopePaperFill /></div>
                                <span>My Messages</span>
                            </div>
                        </Link>

                    </div>



                </div>}
        </div>

    )
}

export default Sidebar