import { getDownloadURL, listAll, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsCloudDownload } from 'react-icons/bs';
import { storage } from '../../firebase';
import { Spinner } from 'react-bootstrap';
const SeeOwnerFiles = (props) => {
    const [show, setShow] = useState(false);
    const {owner} = props
    const [loading, setLoading] = useState(true)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [images, setImages] = useState([])

    useEffect( ()=>{
        const handleRequest= ()=>{
            const listRef = ref(storage, `${owner.email}_${owner.id}/${owner.merchants[(owner.merchants.length)-1].display_name}_${owner.merchants[(owner.merchants.length)-1].id}/VerificationDocument`);
                  // Find all the prefixes and items.
                                    let arr = []
                                    listAll(listRef).then((res) => {
                                      // console.log(res)
                                      res.items.forEach( item => {
                                        arr.push(item.fullPath)
                                      })
                                      const listRef = ref(storage, `${owner.email}_${owner.id}/${owner.merchants[(owner.merchants.length)-1].display_name}_${owner.merchants[(owner.merchants.length)-1].id}/VerificationDocument/driving_license`);
                                      return listAll(listRef)
                                    }).then( res => {
                                      // console.log(res)
                                      res.items.forEach( item => {
                                        arr.push(item.fullPath)
                                      })
                                      const listRef = ref(storage, `${owner.email}_${owner.id}/${owner.merchants[(owner.merchants.length)-1].display_name}_${owner.merchants[(owner.merchants.length)-1].id}/VerificationDocument/passport`);
                                      return listAll(listRef)
                                    } ).then( res => {
                                      res.items.forEach( item => {
                                        arr.push(item.fullPath)
                                      })
                                      return Promise.allSettled( arr.map( path =>{
                                        return getDownloadURL(ref(storage, path))
                                      }))
                                    }).then((res)=>{
                                      // console.log(res.map(item => item.value))
                                        setImages( res.map(item => item.value ) )
                                    }).catch( error => {
                                        console.error(error);
                                    }).finally( () => {
                                        setLoading(false)
                                    })
        }
        if(show){
            setLoading(true)
            handleRequest()
        }
    }, [show])
  return (<>
   <Button title='See Owner Files' variant="light" style={{ color: '#888' }} onClick={handleShow}>
   <BsCloudDownload />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{owner.first_legal_name && owner.first_legal_name} {owner.last_legal_name && owner.last_legal_name} files:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            { loading  ? <div style={{ display:'flex', placeContent:'center' , alignItems:'center', minWidth:'100%', minHeight:'50vh'}}><Spinner animation='border' /></div> :
        <>
        {images.length > 0 ? <div style={{display:'flex', alignContent:'center', justifyItems:'center', flexDirection:'column',alignItems:'center'}}>
            {images.map( path => {
                return <img key={path} style={{width:'10rem'}} src={path}/>
                {/* <img style={{width:'10rem'}} src={}/> */}
            })}
            </div> : <h3>No Files Uploaded!!</h3>}
        </>
        }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  </>)
}

export default SeeOwnerFiles