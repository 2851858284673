import React, { useEffect, useState } from "react";

import "./adminTable.css";
import AdminTableHeader from "../AdminTableHeader";
import { Form, Spinner } from "react-bootstrap";
import TotalAmountCharges from "../TotalAmountCharges";
import Axios from "../axios/AxiosOnboarding";
import PaginationNav from "../PaginationNav";
import { BsCaretDown } from "react-icons/bs";

const ChargesTable = (props) => {
  const [chargesData, setChargesData] = useState([]);
  const [chargesDataArray, setChargesDataArray] = useState([]);

  const [currency, setCurrency] = useState("usd");
  const [isLoading, setIsLoading] = useState(true);

  // state of Pagination Table
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const indexOfLastRow = currentPage * recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - recordsPerPage;
  const currentChargesData =
    chargesDataArray.length > 0
      ? chargesDataArray.slice(indexOfFirstRow, indexOfLastRow)
      : [];

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const moment = require("moment-timezone");

  const getLastMonday = () => {
    const today = moment().tz("America/Los_Angeles");
    if (today.day() === 1) {
      const daysSinceMonday = today.day() - 1; // Calculate days since the last Monday
      const lastMonday = today.subtract(daysSinceMonday, "days");
      lastMonday.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
      return lastMonday;
    }

    const daysSinceMonday = (today.day() + 6) % 7; // Calculate days since the last Monday
    const lastMonday = today.subtract(daysSinceMonday, "days");
    lastMonday.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
    return lastMonday;
  };

  const getNextMonday = () => {
    const today = moment().tz("America/Los_Angeles");
    if (today.day() === 1) {
      const daysUntilMonday = 8 - today.day(); // Calculate days until the next Monday
      const nextMonday = today.add(daysUntilMonday, "days");
      nextMonday.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
      return nextMonday;
    }

    const daysUntilMonday = (8 - today.day()) % 7; // Calculate days until the next Monday
    const nextMonday = today.add(daysUntilMonday, "days");
    nextMonday.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });
    return nextMonday;
  };

  // const lastMonday = getLastMonday().tz("America/Los_Angeles").toISOString();
  // const nextMonday = getNextMonday().tz("America/Los_Angeles").toISOString();
  const lastMonday = getLastMonday().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  const nextMonday = getNextMonday().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  // console.log('lastMonday', lastMonday, 'nextMonday', nextMonday);

  useEffect(() => {
    getChargesList();
  }, [currency]);

  useEffect(() => {
    getChargesList();
  }, [props.merchantC.id]);

  const getChargesList = async () => {
    // console.log({'currency' : currency,
    //   'lastMonday' : lastMonday,
    //   'nextMonday' : nextMonday})

    //  convert Time Zone to PST Time Zone

    await Axios.post(`/payout/charges-list/${props.merchantC.id}`, {
      currency: currency,
      lastMonday: lastMonday,
      nextMonday: nextMonday,
    })
      .then((response) => {
        // const responseData = Array.isArray(response.data) ? response.data : [response.data];
        setChargesData(response.data);
        // console.log(response.data)
        if (response.data.charges_list && response.data.charges_list) {
          setChargesDataArray(response.data.charges_list);
        } else {
          setChargesDataArray([]);
        }
        // console.log('response get Charges:', response.data );
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching charges data:", error);
      });
  };

  const Months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const converTime = (unix_timestamp) => {
    var date = new Date(unix_timestamp * 1000);
    const minutes = ("0" + `${date.getMinutes()}`).slice(-2);
    const seconds = ("0" + `${date.getSeconds()}`).slice(-2);
    return `${
      Months[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}, ${date.getHours()}:${minutes}:${seconds} `;
  };






  return (
    <div className="admin-table-container">
      <AdminTableHeader
        tableTitle={props.tableTitle}
        // options={props.options}
        handleSelectChange={props.handleSelectChange}
        search={props.search}
      />
      <div
        style={{
          justifyContent: "space-around",
          display: "flex",
          marginBottom: "2rem",
        }}
      >
        <Form.Group style={{ width: "15%" }}>
          <Form.Label style={{ color: "gray", fontSize: "12px" }}>
            Currency
          </Form.Label>
          <Form.Select
            as="select"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            {props.filterCurrencylist.map((currencyItem, index) => (
              <option key={index} value={currencyItem} id="options">
                {currencyItem}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <div style={{ width: "15%" }}>
          <Form.Label style={{ color: "gray", fontSize: "12px" }}>
            No.Rows / page <BsCaretDown />{" "}
          </Form.Label>
          <Form.Select
            id="outlined-basic"
            variant="outlined"
            as="select"
            label="Filter"
            value={recordsPerPage}
            onChange={(e) => setRecordsPerPage(e.target.value)}
          >
            <option value={5} id="options">
              {5}
            </option>
            <option value={10} id="options">
              {10}
            </option>
            <option value={15} id="options">
              {15}
            </option>
            <option value={20} id="options">
              {20}
            </option>
            <option value={25} id="options">
              {25}
            </option>
          </Form.Select>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "65ch",
            display: "flex",
            placeContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!isLoading && chargesDataArray && chargesDataArray.length > 0 ? (
        <>
          <table>
            <thead style={{ alignContent: "center" }}>
              <tr style={{ color: "#8085e3" }}>
                <th>Payer Name</th>
                <th>Email</th>
                <th>Amount</th>
                <th>Currency</th>
                <th>Description</th>
                <th>Status</th>
                <th>Date , Time</th>
              </tr>
            </thead>
            <tbody>
              {/* {console.log(chargesData)} */}
              {
                // chargesDataArray && chargesDataArray.length > 0 &&
                currentChargesData.map((merchantCharges, idx) => (
                  <tr key={idx}>
                    <td>{merchantCharges.billing_details.name}</td>
                    <td>{merchantCharges.billing_details.email}</td>
                    <td>
                      {merchantCharges.amount
                        ? (merchantCharges.amount / 100).toFixed(2)
                        : "N/A"}
                    </td>
                    <td>{merchantCharges.currency.toUpperCase()}</td>
                    <td>{merchantCharges.description}</td>
                    <td>{merchantCharges.status}</td>
                    <td>{converTime(merchantCharges.created)}





</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          <PaginationNav
            recordsPerPage={recordsPerPage}
            totalRows={chargesDataArray.length}
            currentPage={currentPage}
            paginate={paginate}
          />

          <TotalAmountCharges chargesData={chargesData} currency={currency} />
        </>
      ) : (
        <div
          style={{
            display: "flex",
            placeContent: "center",
            height: "20ch",
            fontSize: "18px",
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          No charges data available
        </div>
      )}
    </div>
  );
};

export default ChargesTable;