import React from 'react';
import '../Dashboard.css'
import { Card,Row} from 'react-bootstrap';
import { BsFillBriefcaseFill,BsFillHouseDoorFill , BsFillPeopleFill} from "react-icons/bs";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton';

const Cards = ({ accountsNumber, isLoading }) => {
    return (
      <div className='Cards'>
          <div className='ParentContainer'>
          <Row className='wrapper' xs={1} sm={1} md={1} lg={3}>
              <Card  id="card" > 
              <Card.Body id="cardBody">
              <Card.Title id="cardIcon1" ><BsFillHouseDoorFill /></Card.Title>
              <div id="cardBody2">
              <Card.Text style={{color:'rgb(189, 189, 189)'}}>Team Account</Card.Text>
               <Card.Title>
                {isLoading && <Skeleton style={{width:'100px'}} />}
                {!isLoading && <>
                  <span id="Number">{accountsNumber.number_of_team}</span>
                  <span id="title">Account</span>
                  <span id="status">Active</span>
                  </>}
                  </Card.Title>
                  </div>
              </Card.Body>
              </Card>
  
              <Card id="card">
              <Card.Body id="cardBody">
              <Card.Title id="cardIcon2" ><BsFillBriefcaseFill /></Card.Title>
              <div id="cardBody2">
              <Card.Text style={{color:'rgb(189, 189, 189)'}}>Owner</Card.Text>
               <Card.Title>
               {isLoading && <Skeleton style={{width:'100px'}} />}
                {!isLoading && <>
                  <span id="Number">{accountsNumber.number_of_owners}</span>
                  <span id="title">Owner</span>
                  <span id="status">Active</span>
                  </>}
                  </Card.Title>
                  </div>
              </Card.Body> 
              </Card>
  
              <Card id="card">
              <Card.Body id="cardBody">
              <Card.Title id="cardIcon3" ><BsFillPeopleFill /></Card.Title>
              <div id="cardBody2">
              <Card.Text style={{color:'rgb(189, 189, 189)'}}>Merchant</Card.Text>
               <Card.Title>
               {isLoading && <Skeleton style={{width:'100px'}}/>}
                {!isLoading && <>
                  <span id="Number">{accountsNumber.number_of_merchants}</span>
                  <span id="title">Merchant</span>
                  <span id="status">Active</span>
                  </>}
                  </Card.Title>
                  </div>
              </Card.Body>             
              </Card>
          </Row>
  
          </div>    
      </div>
    )
  }
  
  export default Cards