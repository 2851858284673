import React, { useContext } from 'react'
import AuthContext from '../context/AuthContext'
import profile from '../components/profile.png'
import profile2 from '../components/profile2.png'
import UpdateProfileModal from '../components/modals/UpdateProfileModal'

function ProfileData() {
    let {user, logoutUser} = useContext(AuthContext)
  return (
    <>
    <div className='container emp-profile' >
            <div className='row'>
                <div className='col-md-2'>
                    <img style={{width:"100%", height:'auto', paddingLeft:'1rem'}} src={profile2} alt="profile img"/> 
                </div>

                <div className='col-md-6'>
                    <div className='profile-head' style={{marginLeft:'1rem'}}>
                        <h5>{user.first_legal_name} {user.last_legal_name}</h5>
                        <h6>{user.position.toUpperCase()}</h6>
                    </div>
                </div>

                <div className='col-md-4' >
                    <div style={{backgroundColor:'#F2EFFF', display:'flex', placeContent:'center', borderRadius:'10px', border:'1px solid rgb(128,133,227)', width:'70%'}}>
                        <UpdateProfileModal />
                    </div>
                </div>
            </div>
            <div className='row' style={{padding:'3rem'}}>
                <div className='col-md-11'>
                    <div className='row p-2' >
                        <div className='col-md-6'>
                            <label><strong>Email</strong></label>
                        </div>
                        <div className='col-md-6'>
                            <label><strong>{user.email}</strong></label>
                        </div>
                    </div>

                    <div className='row p-2'>
                        <div className='col-md-6'>
                            <label><strong>Phone</strong></label>
                        </div>
                        <div className='col-md-6'>
                            <label><strong>{user.phone_number}</strong></label>
                        </div>
                    </div>

                    <div className='row p-2'>
                        <div className='col-md-6'>
                            <label><strong>Role Permission</strong></label>
                        </div>
                        <div className='col-md-6'>
                            <label><strong>{user.role}</strong></label>
                        </div>
                    </div>

                    <div className='row p-2'>
                        <div className='col-md-6'>
                            <label><strong>ID</strong></label>
                        </div>
                        <div className='col-md-6'>
                            <label><strong>{user.id}</strong></label>
                        </div>
                    </div>

                </div>

            </div>
    </div>
    </>
  )
}

export default ProfileData
