import React, { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { AiFillPlusCircle } from 'react-icons/ai'
import { createPaymentLink } from '../../../common/services/paymentLinks';

const CreatePaymentLinkButton = ({buttonsDisabled, priceID, productID, setButtonsDisabled, setData}) => {
  const [isLoading, setIsLoading] = useState(false);

  const clickHandler = async () => {
    let returnedProduct = await createPaymentLink(priceID, productID, setLoadingStates);
    if (returnedProduct && returnedProduct.id) {
      setData((previousData) => previousData.map((product) => product.id === returnedProduct.id ? {...returnedProduct} : product));
    }
    else {

    }
  }
  const setLoadingStates = (value) => {
    setIsLoading(value);
    setButtonsDisabled(value);
  }

  return (
    <Button
      size="sm"
      variant="outline-secondary"
      onClick={clickHandler}
      disabled={buttonsDisabled || isLoading}
    >
      {isLoading ? <Spinner animation='border' size='sm' /> : <><AiFillPlusCircle />Create Payment Link</>}
    </Button>
  )
}

export default CreatePaymentLinkButton