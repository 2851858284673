import React, { useState }  from 'react'
import OwnerTeble from './tables/OwnerTeble';
import { Card,Row, Button, Form} from 'react-bootstrap';
import { BsCaretDown } from "react-icons/bs";
import './Tables.css'

function AdminOwnerData() {

  const [ recordsPerPage, setRecordsPerPage ] = useState(5)
  const [value,setValue] =useState('')

  return (
    <div style={{width:'99%', marginTop:'2rem',marginBottom:'2rem'}}>

      <div style={{display:'flex', justifyContent:'space-between', padding:'2rem', backgroundColor:'white', borderRadius:'1rem', marginBottom:'1rem'}}>
        <h4 id="tableTitle">Owner Accounts Data</h4>

        <div className="filter" style={{marginTop:'-1.5rem'}}>
            <Form.Label style={{color:'gray', fontSize:'12px'}}>No.Owner / pag <BsCaretDown/></Form.Label>
              <Form.Select
                id="outlined-basic"
                variant="outlined"
                as="select"
                label="Filter"
                value={recordsPerPage} 
                onChange={(e)=> setRecordsPerPage(e.target.value)}
              >
                    <option value={5} id="options">{5}</option>
                    <option value={10} id="options">{10}</option>
                    <option value={15} id="options">{15}</option>
                    <option value={20} id="options">{20}</option>
              </Form.Select>
        </div> 
      </div>
      <OwnerTeble recordsPerPage={recordsPerPage} setRecordsPerPage={setRecordsPerPage} value={value}/>
      
    </div>
  )
}

export default AdminOwnerData