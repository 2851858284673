import React, { useEffect, useReducer, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { RiDeleteBin6Line } from "react-icons/ri";

import currenciesJSON from "../../common/files/currencies.json";

const currencies = JSON.parse(JSON.stringify(currenciesJSON));

const priceReducer = (state, action) => {
  if (action.type === "AMOUNT_CHANGE") {
    let currency = JSON.parse(state.currency);
    let isValid = true;
    let value = action.val;
    if (currency.minimum > +action.val) {
      isValid = false;
    }
    const indexOfDot = value.indexOf(".");
    if (indexOfDot !== -1) {
      value = value.slice(0, indexOfDot+3)
    }
    return {
      amount: value? +value: "",
      isAmountValid: isValid,
      currency: state.currency,
      canAddPrice: isValid,
    };
  } else if (action.type === "CURRENCY_CHANGE") {
    return {
      amount: "",
      isAmountValid: true,
      currency: action.val,
      canAddPrice: false,
    };
  } else if (action.type === "PRICE_ADDED") {
    return {
      amount: "",
      isAmountValid: true,
      currency: state.currency,
      canAddPrice: false,
    };
  }
  return {
    amount: "",
    isAmountValid: false,
    currency: JSON.stringify({ name: "USD", minimum: 0.5, symbol: "$" }),
    canAddPrice: false,
  };
};
const PriceInput = ({ isLoading, addPrice, deletePrice, styles, prices }) => {
  const [pricesLength, setPricesLength] = useState(prices.length);
  const [priceState, dispatchPrice] = useReducer(priceReducer, {
    amount: "",
    isAmountValid: true,
    currency: JSON.stringify({
      name: "USD",
      minimum: 0.5,
      symbol: "$",
    }),
    canAddPrice: false,
  });

  useEffect(() => {
    if (pricesLength > prices.length) {
      dispatchPrice({
        type: "PRICE_ADDED"
      })
    }
    setPricesLength(prices.length);
  }, [prices.length]);

  const amountChangeHandler = (event) => {
    dispatchPrice({
      type: "AMOUNT_CHANGE",
      val: event.target.value,
    });
  };
  const selectChangeHandler = (event) => {
    dispatchPrice({
      type: "CURRENCY_CHANGE",
      val: event.target.value,
    });
  };
  const addHandler = (event) => {
    addPrice({...priceState});
  }

  return (
    <Form.Group>
      <label>Price</label>
      <div className={styles["price-field"]}>
        <div className={styles["input-group-container"]}>
          <InputGroup>
            <InputGroup.Text size="sm">
              {JSON.parse(priceState.currency).name.toUpperCase()}
            </InputGroup.Text>
            <Form.Control
              size="sm"
              type="number"
              step="0.1"
              disabled={isLoading}
              value={priceState.amount}
              onChange={amountChangeHandler}
              placeholder={JSON.parse(priceState.currency).minimum}
              min={JSON.parse(priceState.currency).minimum}
            />
            <Form.Select
              value={priceState.currency}
              size="sm"
              disabled={isLoading}
              onChange={selectChangeHandler}
            >
              {currencies[0] &&
                currencies.map(
                  (currency) =>
                    currency.name && (
                      <option key={currency.name} value={JSON.stringify(currency)}>
                        {currency.name}
                      </option>
                    )
                )}
            </Form.Select>
          </InputGroup>
          <Button type="button" className={styles["add-button"]} onClick={addHandler} disabled={isLoading || !priceState.canAddPrice}>
            Add Price
          </Button>
        </div>
        {prices && prices[0] &&
          <table className={styles["prices-table"]}>
            <thead>
              <tr>
                <th>Amount</th>
                <th>Currency</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {prices.map((price, idx) => (
                <tr key={`${price.value}_${price.currency}`}>
                  <td>{price.value}</td>
                  <td>{price.currency.toUpperCase()}</td>
                  <td>
                    <Button
                      disabled={isLoading}
                      onClick={() => deletePrice(idx)}
                    ><RiDeleteBin6Line /></Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        }
      </div>
    </Form.Group>
  );
};

export default PriceInput;
