import React, { useContext,useState } from 'react';
import AdminLoginPaper from '../components/AdminLoginPaper';
import { Row , Col} from 'react-bootstrap';
import AuthContext from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import { getCookie } from 'typescript-cookie';


const AdminLogin = () => {
  // let {authTokens, role} = useContext(AuthContext)
  let [role, setrole] = useState(() =>  getCookie('role') ? JSON.parse(getCookie('role')) : "");
  let [authTokens, setAuthTokens] = useState(() =>  getCookie('jwt_authtoken') ? (getCookie('jwt_authtoken')) : null);

  return (
    <>
    {!authTokens ? <Col
                  // id="secondColumn"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
    <div style={{paddingTop:'10rem', marginRight:'0px'}}>
      <AdminLoginPaper />
    </div>
    </Col> : <> { role == 'financial' ? <Navigate to='/sms' /> : <Navigate to='/' /> }</> }
    
    </>
  )
}

export default AdminLogin
