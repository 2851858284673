const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const specialCurrencies = [
  "bif",
  "clp",
  "djf",
  "gnf",
  "jpy",
  "kmf",
  "krw",
  "mga",
  "pyg",
  "rwf",
  "ugx",
  "vnd",
  "vuv",
  "xaf",
  "xof",
  "xpf",
];
export const getDateFromTimestamp = (unix_timestamp) => {
  var date = new Date(unix_timestamp * 1000);
  var year = `${date.getFullYear()}`.substring(2, 5);
  return `${Months[date.getMonth()]} ${date.getDate()}, ${year}`;
};
export const formatPrice = (price) => {
  let currencyType = price.metadata.currency_type,
    currency = price.currency,
    amountInCents = price.unit_amount;

  if (
    currencyType &&
    typeof currencyType === "string" &&
    currencyType.toLowerCase() === "jod"
  ) {
    return `JOD ${((amountInCents / 100) * 0.708).toFixed(2)}`;
  } else if (specialCurrencies.includes(currency)) {
    return `${currency.toUpperCase()} ${amountInCents.toFixed(2)}`;
  } else {
    return `${currency.toUpperCase()} ${(amountInCents / 100).toFixed(2)}`;
  }
};
