import {  useState } from "react";
import { Button , Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import { BsChevronLeft ,BsChevronRight } from "react-icons/bs";
import AxiosInstanceMENACartServer from "../../axios/AxiosInstanceMENACartServer";

function ProductsPagination(props) {
    const { hasMore , data , setData , URL } = props
    const [loading , setLoading ] = useState(false)
    const [disablePrevious , setDisablePrevious ] = useState(true)
    const [disableNext, setDisableNext ] = useState(!hasMore)
    const [nextLoading , setNextLoading ] = useState( false )
    const [prevLoading , setPrevLoading ] = useState( false )
    const [firstProduct, setFirstProduct] = useState(data[0].id);

    async function handleNext () {
      setLoading(true)
      setNextLoading(true)
      AxiosInstanceMENACartServer.post(`${URL}`,
      JSON.stringify({
        method:'starting_after',
        limit : 10,
        product_id :  data[data.length - 1].id,
        status : props.status,
        is_active: true,
      })).then((res) => {
        // console.log('data from Pagination',res)
        setData(res.data)
        // props.onClickBagenation(res.data.data)
        setDisableNext(!res.data[res.data.length - 1].has_more)
        setLoading(false)
        setNextLoading(false)
        setDisablePrevious(false)
      }).catch((error)=> {
        console.log("Error in fetching the next page", error)
        toast.error('Something went wrong')
      })
    }

    async function handlePrevious () {
      setLoading(true)
      setPrevLoading( true )
      setDisablePrevious(true)
      AxiosInstanceMENACartServer.post(`${URL}`, 
      JSON.stringify({
        method: "ending_before",
        limit: 10,
        product_id: data[0].id,
        status : props.status,
        is_active: true,
      })).then((res) => {
        setData(res.data)
        // props.onClickBagenation(res.data.data)
        setLoading(false)
        setPrevLoading(false);

        setDisablePrevious(res.data.find((element) => element.id === firstProduct ? true : false))
        setDisableNext(false)
      }).catch((error)=> {
        console.log("Error in fetching the Pre page", error)
        toast.error('Something went wrong')
      })
    }

  return (
    <div style={{maxWidth:'80px' , display:'flex', alignItems:'center', justifyContent:'space-between' , marginTop:'1rem'}} >
        <Button 
          style={{ fontSize:'12px', backgroundColor: "#8085E3", color:'white', fontWeight:'bold', border:'none', boxShadow:"1px 1px 1px 0px rgba(0,0,0,0.25)", marginInline:'0.5rem'}} 
          onClick={handlePrevious}
          disabled ={disablePrevious || loading}
          >
            {prevLoading ? 
            <Spinner animation="border" size='sm' style={{backgroundColor:'#635BFF'}}/> 
            : 
            <BsChevronLeft />}
        </Button>

        <Button 
          style={{ fontSize:'12px', backgroundColor: "#635BFF", color:'white', fontWeight:'bold', border:'none', boxShadow:"1px 1px 1px 0px rgba(0,0,0,0.25)"}} 
          onClick={handleNext}
          disabled={disableNext || loading}
          >
            {nextLoading ? 
            <Spinner animation="border" size='sm' style={{backgroundColor:'#8085E3'}}/> 
            :
            <BsChevronRight />
            }
        </Button>
    </div>
  )
}

export default ProductsPagination
