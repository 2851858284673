import { useState,useEffect } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { BsPencilSquare} from "react-icons/bs";
import PhoneNumberInput from '../PhoneNumberInput';
import { toast } from 'react-toastify';
import Axios from '../axios/AxiosOnboarding';

const EditAccountModal = (props) => {
    const [show, setShow] = useState(false);
    const { member } = props

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [firstName, setFirstName] = useState(member.first_legal_name ? member.first_legal_name : '');
    const [lastName, setLastName] = useState(member.last_legal_name ? member.last_legal_name : '');
    const [email, setEmail] = useState(member.email ? member.email : '');
    const [position, setPosition] = useState(member.position ? member.position : '');
    const [phoneNumber, setPhoneNumber] = useState();
    const [role, setRole ] = useState(member.role ? member.role : '');
    const [status, setStatus ] = useState(member.is_active ? member.is_active : false);
    const [loading , setloading] = useState(false)

    useEffect(()=>{
      if(show){  
        setStatus(member.is_active ? member.is_active : false)
        setFirstName(member.first_legal_name ? member.first_legal_name : '')
        setLastName(member.last_legal_name ? member.last_legal_name : '')
        setEmail(member.email ? member.email : '')
        setPosition(member.position ? member.position : '')
        setRole(member.role ? member.role : '')
      }
    },[show])


    // the route not ready
    const handleUpdate = () => {
      setloading(true)
      Axios.put(`/update-account/${member.id}`,
      {first_legal_name: firstName,
        last_legal_name: lastName,
        email: email,
        phone_number: phoneNumber,
        role : role,
        position : position,
        is_active : status,
      })
      .then((response)=>{
        // console.log('success Updated Member',response);
        props.setReload( current => !current)
        toast.success('Data Updated')
      })
      .catch((error) => {
        toast.error('Something went wrong')
        // console.log('Error updating Merchant:', error)
      })
      .finally( () => {
        setloading(false)
        handleClose()
      });

    }

  return (
    <div>
      <Button variant="light" style={{ color: 'rgb(98,77,227)' }} onClick={handleShow}>
        <BsPencilSquare />
      </Button>

      <Modal show={show} onHide={handleClose} style={{marginTop:'8rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>Edit Team Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form>
                <Form.Group controlId="status" className='mb-3'>
                    <Form.Label>Role : <span style={{fontSize:'12px', color:'green'}}>{member.role}</span></Form.Label>
                    <Form.Control
                    as="select" 
                    value={role}
                    onChange={(e) => setRole(e.target.value)} 
                    
                    >
                    <option value="">Select Role</option>
                    <option value="User">User</option>
                    <option value="Admin">Admin</option>
                   </Form.Control>
                </Form.Group>

                <Form.Group controlId="firstName" className='mb-3'>
                <Form.Control
                type="text"
                value={firstName}
                placeholder='First Name'
                onChange={(e) => setFirstName(e.target.value)}
                />
                </Form.Group>

                <Form.Group controlId="lastName" className='mb-3'>
                <Form.Control
                type="text"
                value={lastName}
                placeholder='Last Name'
                onChange={(e) => setLastName(e.target.value)}
                />
                </Form.Group>

                <Form.Group controlId="email" className='mb-3'>
                    <Form.Control
                    type="email"
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="position" className='mb-3'>
                <Form.Control
                type="text"
                value={position}
                placeholder='Position'
                onChange={(e) => setPosition(e.target.value)}
                />
                </Form.Group>

                <Form.Group style={{display:'flex' , justifyContent:'space-evenly' }} controlId="status" className='mb-3'>
                <Form.Label>Status</Form.Label>
                <Form.Check
                type="checkbox"
                checked={status}
                placeholder='status'
                onChange={(e) => setStatus(e.target.checked)}
                />
                </Form.Group>

                <Form.Group controlId="phoneNumber" className='mb-3'>
                  <PhoneNumberInput 
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}/>

                </Form.Group>
            </Form>
        </Modal.Body>

        <Modal.Footer style={{justifyContent:'center',justifyItems:'center'}}>
          <Button style={{ color: 'white', backgroundColor:'rgb(98,77,227)', border:'none', width:'80%'}} onClick={handleUpdate} disabled={loading}>
          {loading  ? <Spinner animation='border' variant='light' size='sm' />: <><BsPencilSquare /> Update</>}
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  )
}

export default EditAccountModal;
