import React, { useState, useEffect } from "react";
import AdminTable from "../components/tables/AdminTable";
// import moment from 'moment'; 
import MerchantChargesTable from "../components/tables/MerchantChargesTable";
import Axios from "../components/axios/AxiosOnboarding";



const Payout = () => {
  const [isNexMerchanttDisable, setIsNextMerchantDisable] = useState(false);
  const [isPreviousMerchantDisabled, setIsPreviousMerchantDisabled] = useState(true);
  const [isNexChargestDisable, setIsNextChargesDisable] = useState(false);
  const [isPreviousChargesDisabled, setIsPreviousChargesDisabled] = useState(true);


  const [merchantWithChargesList, setMerchantWithChargesList] = useState([])
  const [filterCurrencylist, setFilterCurrencylist] = useState([])
  const [marchentsCurrencyList, setmarchentsCurrencyList] = useState([])

  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getMerchantChargesData();
  }, []);

  const getMerchantChargesData = async () => {

    // convert Time Zone to PST Time Zone
    const convertTimeZone = (date, timeZone) => {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone,
          }
        )
      );
    };

    const todayDate = convertTimeZone(new Date(), "America/Los_Angeles").toISOString();
    // const nextMonday = convertTimeZone(getNextMonday(), "America/Los_Angeles").toISOString();

    // console.log('todayDate',todayDate )
    // console.log(typeof(todayDate))
    // ----------

    try {
      const responce = await Axios.post(`/payout/merchant-list`,
        {
          todayDate: todayDate,
          // nextMonday: nextMonday,
        })
      // console.log('responce?.data',responce?.data)
      setMerchantWithChargesList(responce?.data?.outputAccounts)
      setFilterCurrencylist(responce?.data?.filterCurrency)
      setmarchentsCurrencyList(responce?.data?.marchentsCurrency)
      setIsLoading(false);
      // console.log('response get Merchant with Charges:', responce );
    } catch (error) {
      console.error('Error get Merchant with Charges:', error);
    }
  };


  const search = (serach) => {
    // console.log('searchTerm :>> ', serach);
  }
  const nextPage = () => { }
  const previousPage = () => { }

  return (
    <>
    <h3 className='header'>Payouts</h3>
      <MerchantChargesTable
        tableTitle={"Payouts"}
        search={search}
        merchantWithChargesList={merchantWithChargesList}
        isLoading={isLoading}
        getMerchantChargesData={getMerchantChargesData}
        setMerchantWithChargesList={setMerchantWithChargesList}
        filterCurrencylist={filterCurrencylist}
        marchentsCurrencyList={marchentsCurrencyList}

      />
    </>
  );
}

export default Payout;