import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsCash,BsCurrencyExchange} from "react-icons/bs";
import { useParams } from 'react-router-dom';
import ChargesTable from '../tables/ChargesTable';
import '../Tables.css'

function ChargesModal(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

//   const { merchantId } = useParams();
// console.log('merchantId' , merchantId)

  return (
    <>
      <Button title='show Charges' className='chargesButton' variant="light" style={{ color: 'rgb(98,77,227)' ,fontSize:'20px' }} onClick={handleShow}>
        <BsCurrencyExchange />
      </Button>

      <Modal size="xl" style={{width:'100%'}} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'20px', color: 'rgb(98,77,227)' , fontWeight:'bold'}}>
            Charges for Merchant {props.merchantC.display_name}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <ChargesTable
        tableTitle={"Charges"}
        handleSelectChange={(e)=>console.log(e.target.value)}
        merchantId = {props.merchantId}
        merchantC={props.merchantC}
        filterCurrencylist={props.filterCurrencylist}
        // chargesData = {chargesData}
        />
        </Modal.Body>

        <Modal.Footer>
          <Button style={{ backgroundColor: '#8085e3' ,size:'20px' , border:'none' }} onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ChargesModal;