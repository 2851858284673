import AdminOwnerData from "../components/AdminOwnerData"

const Owner = () => {
    return (
      <div>
        <h3 className='header'>Owner</h3>
        <AdminOwnerData />
  
      </div>
    )
  }
  
  export default Owner 