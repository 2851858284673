import { useEffect, useState } from 'react';
import { Button, Modal, Form, Row ,Col ,Container, Spinner } from 'react-bootstrap';
import { BsPencilSquare} from "react-icons/bs";
import { BsCloudArrowUp } from "react-icons/bs";
import CountrySelector from '../CountrySelector';
import PhoneNumberInput from '../PhoneNumberInput';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from '../../firebase';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import Axios from '../axios/AxiosOnboarding';
// import PhoneNumberInput from '../PhoneNumberInput';

const OwnerUpdateModal = (props) => {

    // console.log(owner)
    const [Loading, setLoading] = useState(false)
    const [show, setShow] = useState(false);
    const {owner} = props
    const owner_id = ''
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [email, setEmail] = useState(owner.email ?? '');
    const [firstLegalName, setFirstLegalName] = useState(owner.first_legal_name ?? '');
    const [lastLegalName, setLastLegalName] = useState(owner.last_legal_name ?? '');
    const [issueCountry, setIssueCountry] = useState(owner.issue_country ?? '' );
    const [countryOfResidence, setCountryOfResidence] = useState(owner.country_of_residence ?? '');
    // console.log(countryOfResidence,issueCountry )
    const [city, setCity] = useState(owner.city ?? '');
    const [address, setAddress] = useState(owner.address ??'');
    // const [postalCode, setPostalCode] = useState(owner.postal_code ?? '');
    const [phoneNumber, setPhoneNumber] = useState();
    const [dob, setDob] = useState('');
    useEffect(()=>{
      if(show){
        // console.log( props )
        setEmail(owner.email ?? '');
        setFirstLegalName(owner.first_legal_name ?? '')
        setLastLegalName(owner.last_legal_name ?? '')
        setCity(owner.city ?? '')
        setAddress(owner.address ??'')
        setIssueCountry(owner.issue_country ?? '' )
        setCountryOfResidence(owner.country_of_residence ?? '')
      }
    }, [show])
    const [imageUploadVerificationDocument , setImageUploadVerificationDocument] = useState()
    const [imageUploadVerificationDocumentFront , setImageUploadVerificationDocumentFront] = useState()
    const [imageUploadVerificationDocumentBack,setImageUploadVerificationDocumentBack,] = useState();
    const [documentType, setDocumentType] = useState('nationalId')

    // Submit the Form
    const [validated, setValidated] = useState(false);
    const handleUpdateOwner = async () => {
        setLoading( true )

        // const emailWithoutSpicalChar = email.match(/[!#$%^&*(),?":{}|<>]/);
        // if (!emailWithoutSpicalChar) { 
        //   let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
          
        //   if (email.toLowerCase().match(mailFormat)) {
        //     setEmail(email.toLowerCase())
        //   } else {
        //     setEmail('');
        //   }
        // }

        const requestBody = {  
          email: email,
          first_legal_name: firstLegalName,
          last_legal_name: lastLegalName,
          // issue_country: issueCountry,
          // country_of_residence: countryOfResidence,
          city: city,
          address: address,
          // postal_code: postalCode,
          phone_number: phoneNumber,
        };
        if( dob ){
          requestBody['dob'] = dob;
        }
        if( countryOfResidence ){
          // console.log(countryOfResidence);
          requestBody['country_of_residence']=countryOfResidence
        }
        if( issueCountry ){
          // console.log(issueCountry);
          requestBody['issue_country']=issueCountry
        }
        // console.log(requestBody)
        // console.log(`${owner.email}_${owner.id}/${owner.merchant_display_name}_${owner.merchant_id}/VerificationDocument/`)
        
              return await Axios.put(`/update-owner/${owner.id}`,requestBody)
              .then(async(res) => {
                // console.log(res.data);
                if( documentType === 'nationalId' && imageUploadVerificationDocumentBack){
                  const imageRefVerificationDocumentBack = ref(
                    storage,
                    `${owner.email}_${owner.id}/${owner.merchant_display_name}_${owner.merchant_id}/VerificationDocument/VerificationDocumentBack`
                    );
                   return await uploadBytes( imageRefVerificationDocumentBack , imageUploadVerificationDocumentBack)
                  }else return;
              }).then( async(res) => {
                if( documentType === 'nationalId' && imageUploadVerificationDocumentFront){

          
                  const imageRefVerificationDocumentFront = ref(
                    storage,
                    `${owner.email}_${owner.id}/${owner.merchant_display_name}_${owner.merchant_id}/VerificationDocument/VerificationDocumentFront`
                    );
                    return await uploadBytes( imageRefVerificationDocumentFront , imageUploadVerificationDocumentFront)
                  }else return;
              }).then(async res => {
                if( documentType !== 'nationalId' && imageUploadVerificationDocument){
                  const imageRefVerificationDocumentFront = ref(
                    storage,
                    documentType === 'passport' ? `${owner.email}_${owner.id}/${owner.merchant_display_name}_${owner.merchant_id}/VerificationDocument/passport/VerificationDocumentFront` :`${owner.email}_${owner.id}/${owner.merchant_display_name}_${owner.merchant_id}/VerificationDocument/driving_license/VerificationDocumentFront`
                    );
                  return await uploadBytes(imageRefVerificationDocumentFront , imageUploadVerificationDocument)
                }else return;
              }).then( res => {
                // props.updateOwner(owner)
                props.setReload( current => !current)
                handleClose();                
              }).catch(error => {
                console.error(error);
              }).finally( () => {
                setLoading( false )
              })
       
            
    }

  return (
    <div>
      <Button title='Update Owner Data' variant="light" style={{ color: 'rgb(98,77,227)'}} onClick={handleShow}>
        <BsPencilSquare />
      </Button>

      <Modal show={show} onHide={handleClose} size="lg"  style={{marginTop:'4rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'20px', color: 'rgb(98,77,227)' , fontWeight:'bold'}}>Update {owner.first_legal_name} {owner.last_legal_name} Data</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form 
            // noValidate
            // validated={validated}
            >
                <Row>
                <Col lg={5} md={12} sm={12} xs={12} >
                    <Form.Group controlId='firstLegalName' className="mb-3">
                        <Form.Control
                        type="text"
                        placeholder='First Name'
                        value={firstLegalName}
                        disabled />
                    </Form.Group>

                    <Form.Group controlId='lastLegalName' className="mb-3">
                        <Form.Control
                        type="text"
                        placeholder='Last Name'
                        value={lastLegalName}
                        disabled />
                    </Form.Group>

                    <Form.Group controlId='dob' className="mb-3">
                        <Form.Control
                        type="date"
                        placeholder='Date of Birth'
                        value={dob}
                        onChange={(e) => setDob(e.target.value)} />
                    </Form.Group>

                   <Form.Group controlId='email' className="mb-3">
                        <Form.Control
                        type="email"
                        value={email}
                        placeholder='Email'
                        // onChange={(e) => setEmail(e.target.value)}
                        disabled
                        />
                    </Form.Group>
                    <label htmlFor='issueCountry'>Issue Country  <span style={{fontSize:'12px', color:'green'}}>{issueCountry}</span></label>
                    <Form.Group controlId='issueCountry' className='mb-3'>
                      <CountrySelector
                      country={issueCountry}
                      setCountry={setIssueCountry} />
                    </Form.Group>
                    <label htmlFor='countryOfResidence'>Country of residence  <span style={{fontSize:'12px', color:'green'}}>{countryOfResidence}</span></label>
                    <Form.Group controlId='countryOfResidence' className='mb-3'>
                      <CountrySelector
                      country_defult={owner.country_of_residence ?? ''}
                      country={countryOfResidence}
                      setCountry={setCountryOfResidence} />

                    </Form.Group>

                    <Form.Group controlId='city' className='mb-3'>
                        <Form.Control
                        type="text"
                        value={city}
                        placeholder="City"
                        onChange={(e) => setCity(e.target.value)} />
                    </Form.Group>

                    <Form.Group controlId='address' className='mb-3'>
                        <Form.Control
                        type="text"
                        value={address}
                        placeholder="Adress"
                        onChange={(e) => setAddress(e.target.value)} />
                    </Form.Group>

                    {/* <Form.Group controlId='postalCode' className='mb-3'>
                        <Form.Control
                        type="text"
                        value={postalCode}
                        placeholder="Postal Code / Zip Code"
                        onChange={(e) => setPostalCode(e.target.value)} />
                    </Form.Group> */}

                    <Form.Group controlId='phoneNumber' className='mb-3'>
                      <PhoneNumberInput 
                      phoneNumber={phoneNumber}
                      setPhoneNumber={setPhoneNumber}
                      />
                    </Form.Group>

                </Col>
                <Col lg={6} md={12} sm={12} xs={12} style={{marginLeft:'1rem'}}>

                  <Row>
                    {/* Verification Document */}
                    <>
                    <Form.Group controlId='formFile' className='mb-3'>
                    
                          <Form.Select value={documentType} onChange={(e)=>{ setDocumentType( e.target.value )}}>
                            <option value='nationalId' key='nationalId'>National Id</option>
                            <option value='passport' key='passport'>Passport</option>
                            <option value='drivingLicense' key='drivingLicense'>Driving License</option>
                          </Form.Select>
                          { documentType === 'nationalId' && <>
                          <Row style={{ marginTop: '10px' }}>

                          <label style={{ fontSize: '14px', fontWeight:'bold', color:'#888', marginBottom:'1rem'}}>
                            National Id (Front Clear) <span style={{fontSize:'24px'}}><BsCloudArrowUp /></span>
                          </label>
                          <Form.Control
                            size='sm'
                            type='file'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => {
                              setImageUploadVerificationDocumentFront(
                                e.target.files[0]
                                );
                              }}
                              />
                          <Row>
                          <span style={{fontSize:'14px', marginTop:'1rem'}}>File added</span>
                              </Row>
                          </Row>
                          <Row style={{ marginTop: '5rem' }}>
                          <label style={{ fontSize: '14px' , fontWeight:'bold', color:'#888', marginBottom:'1rem'}}>
                            National Id (Back Clear)  <span style={{fontSize:'24px'}}><BsCloudArrowUp /></span>
                          </label>
                          <Form.Control
                            size='sm'
                            type='file'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => {
                              setImageUploadVerificationDocumentBack(
                                e.target.files[0]
                              );
                            }}
                          />
                          <Row>
                          <span style={{fontSize:'14px', marginTop:'1rem'}}>File added</span>
                          </Row>
                          </Row>
                          </>}
                          { (documentType === 'passport' || documentType === 'drivingLicense') && <>
                          <Row style={{ marginTop: '1rem' }}>
                          <label style={{ fontSize: '14px' , fontWeight:'bold', color:'#888', marginBottom:'1rem'}}>
                          Verification Document { documentType === 'passport' ? '(Passport)' : '(Driving license)'}  <span style={{fontSize:'24px'}}><BsCloudArrowUp /></span>
                          </label>
                          <Form.Control
                            size='sm'
                            type='file'
                            accept='.png, .jpg, .jpeg'
                            onChange={(e) => {
                              setImageUploadVerificationDocument(
                                e.target.files[0]
                              );
                            }}
                          />
                          <Row>
                          <span style={{fontSize:'14px', marginTop:'1rem'}}>File added</span>
                          </Row>
                          </Row>
                          </>}
                    </Form.Group>
                    </>


                  </Row>

                </Col>
                </Row>
                
            </Form>

        </Modal.Body>

        <Modal.Footer style={{justifyContent:'center',justifyItems:'center'}}>
          <Button style={{ color: 'white', backgroundColor:'rgb(98,77,227)', border:'none', width:'80%'}} onClick={handleUpdateOwner} disabled={Loading}>
      {Loading ? <Spinner variant='light' animation='border' size='sm' /> :
      <>
          <BsPencilSquare /> Update
            </>}
          </Button>
        </Modal.Footer>


      </Modal>
      
    </div>
  )
}

export default OwnerUpdateModal
