import React from "react";
import AdminTableFilters from "./AdminTableFilter";

const AdminTableHeader = (props) => {

  return (
    <div className="table-header">
      <h3>{props.tableTitle}</h3>
      <AdminTableFilters
        options={props.options}
        handleSelectChange={props.handleSelectChange}
        search={props.search}
      />
    </div>
  );
}

export default AdminTableHeader;