import React from 'react'
import { Card,Col,Row} from 'react-bootstrap';
import './Customer.css'
import CircularProgressCustomerBar from './CircularProgressCustomerBar';
import ChartMonthlyCustomer from './ChartMonthlyCustomer';

const NumberCustomerPercentage = (props) => {
    return (
      <div className='CustomerCards'>
        <Row style={{gap:'2rem', margin:'2rem'}}>
            <Col lg={7} md={7} sm={11} xs={11} style={{backgroundColor:'white',borderRadius:'3rem', padding:'2rem'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <h5>Customers</h5>
                    <p style={{color:'rgb(189, 189, 189)'}}>Monthly Customer Percentage</p>
                    <ChartMonthlyCustomer merchantsEachMonth={props.merchantsEachMonth} />
                </div>

            </Col>

            <Col lg={4} md={4} sm={11} xs={11} style={{backgroundColor:'white',borderRadius:'3rem', padding:'2rem'}}>
                <div style={{display:'flex', flexDirection:'column'}}>
                    <h5>Customers</h5>
                    <p style={{color:'rgb(189, 189, 189)'}}>Number of Customer this Month</p>
                    <CircularProgressCustomerBar merchantsThisMonth={props.merchantsThisMonth} isLoading={props.isLoading} />
                </div>
            </Col>

        </Row>
      </div>
    )
  }
  
  export default NumberCustomerPercentage