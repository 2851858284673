import React from 'react';
import CreatePaymentLinkButton from './CreatePaymentLinkButton';
import CopyToClipboardInput from '../../inputs/CopyToClipboardInput';

const ProductPaymentLinksTd = ({prices, productID, buttonsDisabled, setButtonsDisabled, setData}) => {

  return (
    <td>
      {prices && prices[0] &&
        prices.map((price) => ( !price.recurring ?
          <div key={price.id}>
            {
              price.metadata.payment_link 
              ?<CopyToClipboardInput paymentLink={price.metadata.payment_link} />
              :<CreatePaymentLinkButton setData={setData} priceID={price.id} productID={productID} buttonsDisabled={buttonsDisabled} setButtonsDisabled={setButtonsDisabled} />
            }
          </div>
          :
          <div key={price.id}>
            recurring-price
          </div>
        ))
      }
    </td>
  )
}

export default ProductPaymentLinksTd