import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./adminTable.css";
import "../Tables.css";
import { Spinner, Form, Button } from "react-bootstrap";
import { BsCaretDown, BsFilter, BsSearch } from "react-icons/bs";
import AdminTableHeader from "../AdminTableHeader";
import AdminTablePagination from "../AdminTablePagination";
import ChargesModal from "../modals/ChargesModal";
import PaginationNav from "../PaginationNav";

const MerchantChargesTable = (props) => {
  const navigate = useNavigate();
  const [value, setValue] = useState("");

  // state of Pagination Table
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(5);
  const indexOfLastRow = currentPage * recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - recordsPerPage;
  const currentPayoutData = props.merchantWithChargesList.slice(
    indexOfFirstRow,
    indexOfLastRow
  );
  const [currency, setCurrency] = useState("usd");
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const [filteredMerchant, setFilteredMerchant] = useState([]);
  const [editedLastDeposit, setEditedLastDeposit] = useState("");
  const [editedRow, setEditedRow] = useState(-1);

  const handleReset = () => {
    props.getMerchantChargesData();
    setValue("");
    setFilteredMerchant([]);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (value) {
      const filteredData = props.merchantWithChargesList
        .filter((merchant) => {
          return (
            (merchant.support_email &&
              merchant.support_email.includes(value)) ||
            (merchant.display_name.toLowerCase() &&
              merchant.display_name.includes(value.toLowerCase())) ||
            (merchant.status &&
              merchant.status.toLowerCase().includes(value.toLowerCase())) ||
            (merchant.country &&
              merchant.country.toLowerCase().includes(value.toLowerCase())) ||

            (merchant.city &&
              merchant.city.toLowerCase().includes(value.toLowerCase()))
          );
        })
        .slice(indexOfFirstRow, indexOfLastRow);
      props.setMerchantWithChargesList(filteredData);
    }
  };

  const handleEdit = (rowIndex) => {
    setEditedRow(rowIndex);
    setEditedLastDeposit(currentPayoutData[rowIndex]?.lastDeposit || "");
  };

  const handleCancel = () => {
    setEditedRow(-1);
    setEditedLastDeposit("");
  };

  const handleSave = async (merchantC, rowIndex) => {
    try {
      const updatedMerchantC = {
        ...merchantC,
        lastDeposit: editedLastDeposit,
      };

      const response = await axios.put(
        "/update-last-deposit",
        updatedMerchantC
      );

      if (response.status === 200) {
        console.log("Edit successful");
        handleCancel();
      }
    } catch (error) {
      console.error("Edit failed", error);
    }
  };

  return (
    <div className="admin-table-container">
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "1rem",
          marginBottom: "1rem",
        }}
      >
        {/* Currency */}
        <div style={{ display: "flex", height: "2.4rem", width: "15%" }}>
          <Form.Label
            style={{
              color: "gray",
              fontSize: "12px",
              marginRight: "1rem",
              paddingTop: ".5rem",
            }}
          >
            Currency
          </Form.Label>
          <Form.Select
            as="select"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
          >
            {props.filterCurrencylist.map((currencyItem, index) => (
              <option key={index} value={currencyItem} id="options">
                {currencyItem?.toUpperCase()}
              </option>
            ))}
          </Form.Select>
        </div>

        {/* Search */}
        <div style={{ marginLeft: "3rem" }}>
          <Form
            onSubmit={handleSearch}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Form.Group
              controlId="value"
              className="mb-3"
              style={{ width: "100px" }}
            >
              <Form.Control
                type="text"
                placeholder="Search"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </Form.Group>
            <Button
              size="sm"
              type="submit"
              variant="light"
              className="searchButton charges"
              style={{ color: "rgb(98,77,227)" }}
            >
              <BsSearch />
            </Button>
            <Button
              size="sm"
              onClick={handleReset}
              variant="light"
              className="resetButton charges"
              style={{ color: "rgb(98,77,227)", marginRight: "3rem" }}
            >
              <BsFilter />
            </Button>
          </Form>
        </div>

        <div className="filter1">
          <Form.Select
            id="outlined-basic"
            variant="outlined"
            as="select"
            label="Filter"
            value={recordsPerPage}
            onChange={(e) => setRecordsPerPage(e.target.value)}
          >
            <option value={5} id="options">
              {5}
            </option>
            <option value={10} id="options">
              {10}
            </option>
            <option value={15} id="options">
              {15}
            </option>
            <option value={20} id="options">
              {20}
            </option>
          </Form.Select>
        </div>
      </div>

      <div
        style={{
          padding: "2rem",
          backgroundColor: "white",
          borderRadius: "1rem",
        }}
      >
        {props.isLoading && (
          <div
            style={{
              width: "100%",
              height: "40ch",
              display: "flex",
              placeContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" variant="primary" />
          </div>
        )}
        {!props.isLoading && (
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr style={{ color: "#8085e3" }}>
                  <th></th>
                  <th>ID</th>
                  <th>Display Name</th>
                  <th>Bank Account Number</th>
                  <th>Email</th>
                  <th>Last Deposit </th>
                  <th className="px-5">Currency Options</th>
                </tr>
              </thead>
              <tbody>
                {/* {console.log(currentPayoutData)} */}
                {currentPayoutData.length > 0 ? (
                  currentPayoutData.map((merchantC, idx) => (
                    <tr className="clickable-tr" key={idx}>
                      <td>
                        <ChargesModal
                          merchantC={merchantC}
                          filterCurrencylist={props.filterCurrencylist}
                        />
                      </td>
                      <td>{merchantC.id}</td>
                      <td>{merchantC.display_name}</td>
                      <td>
                        {merchantC.bank_account_num
                          ? merchantC.bank_account_num
                          : "****"}
                      </td>
                      <td>{merchantC.support_email}</td>
                      <td className="d-flex align-items-center justify-content-between">
                        {props?.marchentsCurrencyList[merchantC.id]?.[currency]}
                        
                        {editedRow === idx ? (
                          <div className="d-flex gap-3 flex-wrap px-3">
                            <input
                              type="text"
                              value={editedLastDeposit}
                              onChange={(e) =>
                                setEditedLastDeposit(e.target.value)
                              }
                            />
                            <button
                              className="btn btn-sm btn-outline-primary"
                              onClick={() => handleSave(merchantC, idx)}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-sm btn-outline-danger"
                              onClick={handleCancel}
                            >
                              Cancel
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-sm btn-outline-primary"
                            onClick={() => handleEdit(idx)}
                          >
                            Edit
                          </button>
                        )}
                      </td>

                      <td className="px-5">
                        {props?.marchentsCurrencyList[merchantC.id] &&
                          Object.keys(
                            props.marchentsCurrencyList[merchantC.id]
                          ).map((currency) => (
                            <span key={currency}>
                              {currency.toUpperCase() + " , "}{" "}
                            </span>
                          ))}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="noData">
                      No Data Found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
        {!props.isLoading && (
          <div style={{ padding: "2rem", overflowX: "auto" }}>
            <PaginationNav
              recordsPerPage={recordsPerPage}
              totalRows={props.merchantWithChargesList.length}
              currentPage={currentPage}
              paginate={paginate}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MerchantChargesTable;
