import { useContext, useState } from 'react';
import { Button, Modal, Form, Row ,Col, Spinner } from 'react-bootstrap';
import { BsPencilSquare, BsCloudArrowUp} from "react-icons/bs";
import PhoneNumberInput from '../PhoneNumberInput';
import { toast } from 'react-toastify';
import AuthContext from '../../context/AuthContext';
import Axios from '../axios/AxiosOnboarding';

function UpdateProfileModal() {
  let {user} = useContext(AuthContext)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [ reload , setReload ] = useState(true)

  const [email, setEmail] = useState(user.email);
  const [firstLegalName, setFirstLegalName] = useState(user.first_legal_name);
  const [lastLegalName, setLastLegalName] = useState(user.last_legal_name);
  const [position, setPosition] = useState(user.position);
  const [role, setRole] = useState(user.role);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);
  const [imageProfileUpload , setImageProfileUpload] = useState()
  
  const [loading , setloading] = useState(false)

  const handleUpdateProfile = () => {
    setloading(true)

    Axios.put(`/update-account/${user.id}`,
          {email: email,
          first_legal_name: firstLegalName,
          last_legal_name: lastLegalName,
          position: position,
          phone_number: phoneNumber,
          role : 'user.role',
        })
          .then((Response)=>{
            // console.log(Response)
            setReload( current => !current)
            toast.success('Data Updated')
            toast.success('Please sign in again to view the updated data.')
          })
          .catch((error) => {
            toast.error('Something went wrong')
            console.error('Error updating Owner:', error);
          })
          .finally( () => {
            setloading(false)
            handleClose()
          }); 
  }

  return (
    <>
      <Button variant="white" style={{ color: 'rgb(98,77,227)',fontSize:'14px' }} onClick={handleShow}>
      <BsPencilSquare />  Update
      </Button>

      <Modal show={show} onHide={handleClose} size="lg"  style={{marginTop:'8rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'20px', color: 'rgb(98,77,227)' , fontWeight:'bold'}}>Update Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <Form>
        <Row style={{justifyContent:'center'}}>
        <Col lg={8} md={10} sm={10} xs={10} >
        <Form.Group controlId='firstLegalName' className="mb-3">
        <Form.Control
        type="text"
        placeholder='First Name'
        value={firstLegalName}
        onChange={(e) => setFirstLegalName(e.target.value)} 
         />
        </Form.Group>

        <Form.Group controlId='lastLegalName' className="mb-3">
            <Form.Control
                type="text"
                placeholder='Last Name'
                value={lastLegalName}
                onChange={(e) => setLastLegalName(e.target.value)} 
                />
            </Form.Group>

            <Form.Group controlId='email' className="mb-3">
            <Form.Control
                type="email"
                value={email}
                placeholder='Email'
                onChange={(e) => setEmail(e.target.value)}
                 />
            </Form.Group>

            <Form.Group controlId='position' className="mb-3">
            <Form.Control
                type="text"
                placeholder='Position'
                value={position}
                onChange={(e) => setPosition(e.target.value)} 
            />
            </Form.Group>

            <Form.Group controlId='role' className="mb-3">
            <Form.Control
                type="text"
                placeholder='role'
                disabled
            />
            </Form.Group>

            <Form.Group controlId='phoneNumber' className='mb-3'>
            <PhoneNumberInput 
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                />
            </Form.Group>

        </Col>

    {/* <Col lg={5} md={12} sm={12} xs={12} style={{marginLeft:'1rem'}}>
      <Form.Group controlId='formFile' className='mb-3'>
            <Row style={{ marginTop: '10px' }}>

            <label style={{ fontSize: '14px', fontWeight:'bold', color:'#888', marginBottom:'1rem'}}>
            Add a Personal Photo <span style={{fontSize:'24px'}}><BsCloudArrowUp /></span>
            </label>
                <Form.Control
                    size='sm'
                    type='file'
                    accept='.png, .jpg, .jpeg'
                    onChange={(e) => {setImageProfileUpload(
                        e.target.files[0]);
                        }}
                    />
                    <Row>
                <span style={{fontSize:'14px', marginTop:'1rem'}}>File added</span>
                    </Row>
                </Row>
        </Form.Group>
      </Col> */}

        </Row>
        </Form>
       </Modal.Body>

      <Modal.Footer style={{justifyContent:'center',justifyItems:'center'}}>
        <Button style={{ color: 'white', backgroundColor:'rgb(98,77,227)', border:'none', width:'80%'}} 
        onClick={handleUpdateProfile} disabled={loading}
        >
          {loading ? <Spinner animation='border' variant='light' size='sm'/> : <><BsPencilSquare /> Update</>}
        </Button>
      </Modal.Footer>
      </Modal>
    </>
  );
}

export default UpdateProfileModal;
