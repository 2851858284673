import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Form, Spinner } from "react-bootstrap";
import { validateInputWithRegex } from "../../common/services/validateInput";
import { createProduct } from "../../common/services/products";
import styles from "./CreateProduct.module.css";
import PriceInput from "./PriceInput";
const inputRegex = /^[^*&<>()`#~]*$/;

const nameAndDescriptionReducer = (state, action) => {
  if (action.type === "USER_NAME_INPUT") {
    return {
      nameValue: action.val,
      isNameValid: validateInputWithRegex(action.val, inputRegex),
      descriptionValue: state.descriptionValue,
      isDescriptionValid: state.isDescriptionValid,
    };
  } else if (action.type === "USER_DESCRIPTION_INPUT") {
    return {
      nameValue: state.nameValue,
      isNameValid: state.isNameValid,
      descriptionValue: action.val,
      isDescriptionValid: validateInputWithRegex(action.val, inputRegex),
    };
  }
  return {
    nameValue: "",
    isNameValid: false,
    descriptionValue: "",
    isDescriptionValid: false,
  };
};

const CreateProduct = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [prices, setPrices] = useState([]);

  const navigate = useNavigate();

  const [nameAndDescriptionState, dispatchNameAndDescription] = useReducer(
    nameAndDescriptionReducer,
    {
      nameValue: "",
      isNameValid: true,
      descriptionValue: "",
      isDescriptionValid: true,
    }
  );
  const nameChangeHandler = (event) => {
    dispatchNameAndDescription({
      type: "USER_NAME_INPUT",
      val: event.target.value,
    });
  };
  const descriptionChangeHandler = (event) => {
    dispatchNameAndDescription({
      type: "USER_DESCRIPTION_INPUT",
      val: event.target.value,
    });
  };
  
  const addPrice = (priceState) => {
    if (!priceState.canAddPrice) return;
    if (prices[0]) {
      let dublicatePrice = prices.find((price) =>  (price.value === priceState.amount && price.currency === JSON.parse(priceState.currency)['name'].toLowerCase()));
      if (dublicatePrice) return
    }
    let priceObj = {
      value: parseFloat(priceState.amount),
      currency: JSON.parse(priceState.currency)["name"].toLowerCase(),
      symbol: JSON.parse(priceState.currency)["symbol"],
      recurring: {
        type: "one_time",
      },
    }
    setPrices(previousValue => [...previousValue, priceObj]);
  };

  const deletePrice = (priceIndex) => {
    let pricesList = [...prices];
    pricesList.splice(priceIndex, 1);

    setPrices([...pricesList]);
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    const form  = event.currentTarget;
    if (prices.length < 1) {

    }
    if (form.checkValidity()) {
      const product = await createProduct({
        name: nameAndDescriptionState.nameValue,
        description: nameAndDescriptionState.descriptionValue,
        prices: prices,
      }, setIsLoading);

      if (product && product.id ) {
        navigate("/products");
      }
    }

  }

  return (
    <main>
      <h2>Add Product or Service</h2>
      <Form className={styles["create-form"]} onSubmit={submitHandler}>
        <Form.Group>
          <label htmlFor="name">Name</label>
          <Form.Control
            id="name"
            size="sm"
            placeholder="Premium Plan, sunglasses, etc."
            autoFocus
            maxLength="200"
            minLength="2"
            required
            disabled={isLoading}
            value={nameAndDescriptionState.nameValue}
            isInvalid={!nameAndDescriptionState.isNameValid}
            onChange={nameChangeHandler}
          />
        </Form.Group>
        <Form.Group>
          <label htmlFor="description">Description</label>
          <Form.Control
            id="description"
            size="sm"
            as="textarea"
            autoFocus
            maxLength="200"
            disabled={isLoading}
            value={nameAndDescriptionState.descriptionValue}
            isInvalid={!nameAndDescriptionState.isDescriptionValid}
            onChange={descriptionChangeHandler}
          />
        </Form.Group>
        <PriceInput isLoading={isLoading} addPrice={addPrice} styles={styles} prices={prices} deletePrice={deletePrice} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button type="submit" disabled={isLoading || !nameAndDescriptionState.nameValue || !prices[0]}>
            {isLoading ? <Spinner size="sm" animation="border" /> : "Create"}
          </Button>
        </div>
      </Form>
    </main>
  );
};

export default CreateProduct;
