import { useState, useEffect } from 'react';
import { Button, Modal, Spinner, Form } from "react-bootstrap";
import { AiOutlineReload } from "react-icons/ai";
import { toast } from "react-toastify";
import Axios from '../axios/AxiosOnboarding';

function RechargeSubscription(props) {
  // console.log('props inside modal',props)
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [Loading, setLoading] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [PaymentMethodOptions, setPaymentMethodOptions] = useState();

  const paymentMethodChangeHandler = (event) => {
    setSelectedPaymentMethod(event.target.value);
  }

  useEffect(()=>{
    getPaymentMethodOptions();
  },[]);

  const getPaymentMethodOptions = async () => {
    try{
      const responce = await Axios.get(`/account/payment-methods/${props.merchantId}/${props.customerId}`);
      // console.log('Payment Method Options responce' , responce)
      setPaymentMethodOptions(responce.data)
    } catch (error) {
      console.error('Error fetching Payment Method Options Data:', error);
    }
  }

  const handleRetry = () => {
    setLoading(true);
    Axios.post(`/account/recharge-latest-invoice/${props.merchantId}`,
    {
        subscription_id : props.subId,
        pm_id : selectedPaymentMethod,
    }) .then((res) => {
      toast.success('Subscription Updated')
    }) .catch (error => {
      console.error('error in Subscription recharge Updated',error)
      toast.error('Something went wrong')
    }) .finally (() => {
      setLoading(false);
      handleClose()
      props.setReload( current => !current)
    })
  }

  return (
    <>
      <Button style={{ color: "white",
                backgroundColor: "rgb(98,77,227)",
                border: "none" }} onClick={handleShow}>
        Recharge <AiOutlineReload />
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title style={{fontSize:'18px', color: 'rgb(98,77,227)' }}>Retry Capturing The Payment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {/* {console.log('Payment Method Options responce' , PaymentMethodOptions)} */}
            <p>Do you want to retry charging the Last Invoice in {props.subscriptionDetail.plan.product.name} Subscription?</p>

            <Form.Select
            onChange={(e)=>paymentMethodChangeHandler(e)}
            value={selectedPaymentMethod}
            >
                <option value="">-- Select Payment Method --</option>
                {PaymentMethodOptions && PaymentMethodOptions[0] &&
                PaymentMethodOptions.map((pm) => 
                <option key={pm.id} value={pm.id}> **** {pm.card.last4} / {pm.card.brand}</option>
                )
                }

            </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ color: "white", backgroundColor: "rgb(98,77,227)",border: "none" }} onClick={handleRetry} disabled={Loading || !(selectedPaymentMethod)}>
          {Loading ? <Spinner animation='border' variant='light' size='sm'/> : "Retry"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RechargeSubscription;