import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import ChargesTable from '../components/tables/ChargesTable'
import axios from 'axios';

const Charges = (props) => {
    const { merchantId } = useParams();


  return (
    <div>
        <h1>Charges for Merchant: 
          {merchantId}
          </h1>
        <ChargesTable
        tableTitle={"Charges"}
        options={["a","b","c","d"]}
        handleSelectChange={(e)=>console.log(e.target.value)}
        merchantId = {merchantId}
        // chargesData = {chargesData}
      />
      
    </div>
  )
}

export default Charges
