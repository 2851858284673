import { useState } from 'react';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import { getStatusColor } from '../AdminMerchantData';
import { HiUserGroup } from "react-icons/hi2";


function OwnerMerchantDetailsModal(props) {
  // console.log('OwnerMerchantDetailsModal ',props)
  const [show, setShow] = useState(false);
  const { owner } = props
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
    <Button variant="light" style={{ color: 'rgb(98,77,227)',marginLeft:'0.5rem' }} onClick={handleShow}>
      <HiUserGroup />
    </Button>

    <Modal size='xl' show={show} onHide={handleClose} style={{marginTop:'2rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>{owner.first_legal_name} {owner.last_legal_name} Merchants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {owner.merchants.map((m) => (
              <Row key={m.id} style={{width:'90%', backgroundColor:'#FAFBFF', borderRadius:'5px', padding:'2rem', margin:'2rem'}}>
                <Col lg={5}>
                <h4>{m.display_name}</h4>
                <h6>{m.support_email}</h6>
                </Col>
                <Col lg={6}>
                <p style={{fontSize:'12px', fontWeight:'bold', color:'gray'}}>id : {m.id}</p>
                <p style={{fontSize:'12px', fontWeight:'bold', color:'gray'}}>Status :<span style={{color:getStatusColor(m.status)}}> {m.status}</span></p>
                </Col>

              </Row>

            ))}
        </Modal.Body>

        <Modal.Footer style={{justifyContent:'center',justifyItems:'center'}}>

          {/* <Button variant="secondary" onClick={handleClose}
          style={{ color: 'white', backgroundColor:'rgb(98,77,227)', border:'none'}}>
            Cancel
          </Button> */}
        </Modal.Footer>
    </Modal>
      
    </>
  )
}

export default OwnerMerchantDetailsModal
