import React from "react";
import { Button, FormControl, InputGroup } from "react-bootstrap";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard"

const CopyToClipboardInput = ({paymentLink}) => {
  return (
    <InputGroup>
      <FormControl
        size="sm"
        readOnly
        placeholder={paymentLink}
      />
      <CopyToClipboard text={paymentLink}>
        <Button size="sm" variant="outline-secondary">
          <FaCopy />
        </Button>
      </CopyToClipboard>
      
    </InputGroup>
  );
};

export default CopyToClipboardInput;
