import AxiosInstanceMENACartServer from "../../components/axios/AxiosInstanceMENACartServer";

export const createPaymentLink = async (priceID, productID, setLoading) => {
  if (setLoading) {
    setLoading(true);
  }
  let data;
  try {
    let response = await AxiosInstanceMENACartServer.post("/price/create-payment-link-fintesa", JSON.stringify({
      price_id: priceID,
      product_id: productID,
    }))
    if (response && response.data) {
      data = response.data;
    } else {
      data = null
    }
  } catch (error) {
    console.error(error);
    data = null;
  } finally {
    if (setLoading) {
      setLoading(false);
    }
    return data;
  }

}