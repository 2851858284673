import React, { useContext } from 'react';
import {useNavigate} from 'react-router-dom';
import { Navbar ,Container, Button } from 'react-bootstrap';
import profile from './profile.png';
import UpdateProfileModal from './modals/UpdateProfileModal';
import {  BsBoxArrowLeft, BsFillPersonFill, BsPerson } from "react-icons/bs";
import Dropdown from 'react-bootstrap/Dropdown';
import { Link} from "react-router-dom";
import AuthContext from '../context/AuthContext';
import './Sidebar.css';

const NavBar = () => {


  let {user, logoutUser} = useContext(AuthContext)

  const navigate = useNavigate()
  const navigateToProfilePge = () => {
    navigate(`/profile`)
  }


  return (
     
    <div>
      {user.hasOwnProperty('email') &&
       <div>
      
      <Navbar  className="bg-white">
        <Container style={{justifyContent:'flex-end'}}>

          <Navbar.Brand href="" >
            <div style={{display:'flex', alignItems:'center'}}>
            {/* <img
              alt=""
              src={profile}
              width="30"
              height="30"
            />{' '} */}
            {user &&
            <div style={{display: 'flex', flexDirection: 'column'}}>
            <span style={{fontSize:'14px'}}> 
               {user.first_legal_name} {user.last_legal_name}
               </span>
            <span style={{fontSize:'12px',color:'gray'}}>
              {user.position} / {user.role}
            </span>
            </div>
            } 

             <Dropdown>
             <Dropdown.Toggle variant="white"  style={{border:'none', color:'rgb(98,77,227)'}} />
              <Dropdown.Menu>
              <Dropdown.Item ><Button onClick={navigateToProfilePge} style={{color: 'rgb(98,77,227)',fontSize:'14px'}} variant="white"><BsFillPersonFill /> My Profile</Button></Dropdown.Item>
                <Dropdown.Item href=""><UpdateProfileModal /></Dropdown.Item>
                <Dropdown.Item ><Button onClick={logoutUser} style={{color: 'rgb(98,77,227)',fontSize:'14px'}} variant="white"><BsBoxArrowLeft  /> Logout</Button></Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <Link to="./admin-login" style={{fontSize:'14px'}}>login</Link> */}
            </div>
          </Navbar.Brand>
        </Container>
      </Navbar>
      </div>}
    </div>
  )
}

export default NavBar
