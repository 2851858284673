import React, { useContext } from 'react';
import { useState, useRef } from 'react';
import { Row, Col, Form, Button, Spinner, FormControl} from 'react-bootstrap';
import BackgroundStyle from './images/BackgroundStyle';
import AxiosInstanceOnboardingServer from './axios/AxiosInstanceOnboardingServer';
import AuthContext from '../context/AuthContext';
import axios from 'axios';
import { toast } from 'react-toastify';
import md5 from 'md5-hash';
import Axios from './axios/AxiosOnboarding';

const AdminLoginPaper = () => {
  const [email, setEmail] = useState('');
  const [sentVerificationCode, setSentVerificationCode] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [validated, setValidated] = useState(false);

  const [isLoading, setLoading] = useState(false); // Loading in Button
  const [timer, setTimer] = useState('00:00');

  let {loginUser} = useContext(AuthContext)

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    // Check if the Form is Valid
    if (form.checkValidity()) {
      login_request();
    } else {
      event.stopPropagation();
    }
    setValidated(true);
  }
    
  
  // // Request a verification Code by Write the Email
  async function login_request() {
    setLoading(true);
    await Axios.post(`/account/send-verification-exist-email`,
    {email:email}).then((res) => {
      if (
        res.data ===
        'A Verification Code has Been Sent to your Email, Check Your Inbox, Spam, or Junk!'
      ) {
        toast.success(
          'A Verification Code has Been Sent to your Email, Check Your Inbox, Spam, or Junk!'
        );
        setSentVerificationCode(true);
        setLoading(false);
      } else if (
        res.data ===
        'Something Went Wrong with Sending the Verification Code, Please Try Again!'
      ) {
        toast.error(
          'Something Went Wrong with Sending the Verification Code, Please Try Again!'
        );
        setLoading(false);
      } else {
        toast.error('Email Does Not Exist!');
        setLoading(false);
      }
    }).finally(()=>{setLoading(false)});
  }


  const handleUpdateEmail = (e) => {
    e.preventDefault();
    const emailWithoutSpicalChar = e.target.value.match(/[!#$%^&*(),?":{}|<>]/);

    if (!emailWithoutSpicalChar) { 
      let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,8})+$/;
      const updateEmail = e.target.value.toLowerCase();

      if (updateEmail.match(mailFormat)) {
        setEmail(updateEmail);
      } else {
        setEmail('');
      }
    }
  }

  async function login_verification() {
    setLoading(true);
    // Calculate the Token Expire Date
    var current = new Date();
    var end = new Date();
    end.setHours(23, 59, 59);

    await Axios.post(`/account/check-verification-email`,
    {
      email: email,
      verificationCode: md5(verificationCode),
      // hours: end.getHours() - current.getHours(),
      // minutes: end.getMinutes() - current.getMinutes(),
      // seconds: end.getSeconds() - current.getSeconds(),
    }).then((res) => {
      // console.log(res.data);
      // toast.success('Success')
      setLoading(false);
    })
    .catch((error) =>{
      console.error('Error login:', error);
    }).finally( () => {
      loginUser(email)
    })
  }


  // Timer Functions ->
const onClickTimer = () => {
  clearTimer(getDeadTime());
}

const onClickReset = () => {
  clearTimer(getDeadTime());
  login_request();
}

const Ref = useRef(null);
const clearTimer = (e) => {
  setTimer('05:00');
  if (Ref.current) clearInterval(Ref.current);
  const id = setInterval(() => {
    startTimer(e);
  }, 1000);
  Ref.current = id ;
};

const getDeadTime = () => {
  let deadline = new Date();
  // This is where you need to adjust if  // you entend to add more time
  deadline.setSeconds(deadline.getSeconds() + 5*60);
  return deadline;
}

const startTimer = (e) => {
  let {total, minutes, seconds} = getTimeRemaining(e);
  if (total >=0 ){
    // update timer -> if less than 10 then we need to add '0' at the beginning of variable
    setTimer((minutes>9 ? minutes : '0'+ minutes)+ ':' + (seconds >9 ? seconds : '0' + seconds))
    }
}

const getTimeRemaining = (e) => {
  const total = Date.parse(e) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000)% 60);
  const minutes = Math.floor((total / 1000 / 60)% 60);
  return {total, minutes, seconds};
}
// End Timer Functions <-

  



  return (
    <div>
      <BackgroundStyle />

      <div >
      <Row style={{ position: 'relative' }}>
        <Col lg={4} md={8} sm={8} xs={8}
        style={{
          margin: 'auto',
          textAlign: 'left',
          zIndex: '1',
          position: 'relative',
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
          borderRadius: '5px',
          height:'500px'
        }}>
        <Form 
        noValidate validated={validated} 
        onSubmit={(e)=>{
          handleSubmit(e)
        }} 
        style={{padding :'50px', }}>
          <h2 style={{textAlign:'center', marginBottom:'30px'}}>Admin Login</h2>
          {!sentVerificationCode ? (
            <>

            <Form.Group>
            <label htmlFor='email' style={{fontWeight:'bold'}}>Email address</label>
            <Form.Control
              inline='true'
              type='email'
              name='email'
              placeholder='user@domain.com'
              onChange={handleUpdateEmail}
              required
            />
          </Form.Group>

          <Row>
            <Button
              id = 'buttonSendCode'
              className='Loginbutton Lbutton'
              type='submit'
              disabled={ !email || isLoading}
              style={ email
                ? { marginTop: '10px', marginBottom: '20px' }
                : {
                    border: '1px solid #999999',
                    backgroundColor: '#cccccc',
                    color: '#666666',
                    marginTop: '10px',
                    marginBottom: '20px',
                  }
              }
              onClick={onClickTimer}>
                {isLoading ? (
                  <Spinner animation='border' variant='light' size='sm' />
                ) : (
                  'Send Verification Code (SSO)'
                )}
            </Button>
          </Row>

            </>
          ):(
            <>
            {/* Start Email */}
            <Form.Group>
              <label htmlFor='email' >Email</label>
              <Form.Control
              type='email'
              name='email'
              disabled
              onChange={handleUpdateEmail}
              required />

              <Row style={{justifyContent:'right', display:'flex', textAlign:'right', width:'90%'}}>
                <p style={{cursor:'pointer', fontSize:'12px',width:'30%'}}
                onClick={()=> {
                  setSentVerificationCode(false);
                }}>
                  Change Email
                </p>
              </Row>
            </Form.Group>
             {/* End Email */}

             {/* Start Code */}
             <Form.Group>
              <label htmlFor='code'>Code</label>
              <Form.Control
              type='password'
              name='verification_code'
              defaultValue=''
              required
              onChange={(e)=>{setVerificationCode(e.target.value)}} />
              <Row style={{justifyContent: 'right', display:'flex', textAlign:'right', width:'90%'}}>
                <p style={{cursor:'pointer', fontSize:'12px', width:'50%'}}>
                {timer} Time left to resend!
                </p>
              </Row>
             </Form.Group>
             {/* End Code */}

             <Row lg={2} md={2} sm={2} xs={1} style={{ marginTop: '15px' }}>
              <Col lg={4} md={4} sm={4} xs={12} style={{justifyContent:'center', display:'flex'}}>
                <Row style={
                  timer != '00:00'
                  ? {
                    cursor: 'not-allowed',
                    width: '80%',
                    height: '60%',
                  } : {
                    width: '80%',
                    height: '60%',
                  }
                }>
                  <Button style={
                    timer !== '00:00'
                    ? {
                        cursor: 'not-allowed',
                        pointerEvents: 'none',
                        opacity: '0.65',
                        border: '0px',
                        padding: '5px',
                        aspectRatio: '0',
                        borderRadius: '5px',
                        filter: 'alpha(opacity=65)',
                        fontSize: '14px',
                      }
                    : {
                        border: '0px',
                        padding: '5px',
                        aspectRatio: '0',
                        borderRadius: '5px',
                        fontSize: '14px',
                      }
                  }
                  onClick={onClickReset}>
                    Resend
                  </Button>
                </Row>
              </Col>
             </Row>

             <Row>
              <Button
              id='buttonLogin'
              className='Loginbutton Lbutton'
              onClick={!isLoading ? login_verification : null}
              disabled={!verificationCode || isLoading}
              style={
                verificationCode
                  ? { marginTop: '10px', marginBottom: '20px' }
                  : {
                      border: '1px solid #999999',
                      backgroundColor: '#cccccc',
                      color: '#666666',
                      marginTop: '10px',
                      marginBottom: '20px',
                    }}
              >
                {isLoading ? (
                  <Spinner animation='border' variant='light' size='sm' />
                ) : (
                  'Log In'
                )}
              </Button>
             </Row>

            </>
          )}
          

          </Form>
        </Col>
      </Row>  

    </div>
    </div>
  )
}

export default AdminLoginPaper
