import React , { useContext } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import AuthContext ,{ AuthProvider } from '../context/AuthContext';

const PrivateRoute = () => {
  
    const isAuthenticated = true
    let {user} = useContext(AuthContext)
    let {role} = useContext(AuthContext)
    let {authTokens} = useContext(AuthContext)
    
  return(<>
    {/* {isAuthenticated ? <Outlet /> : <Navigate to='/admin-login' />} */}
    { role!=="financial" && authTokens ?  <Outlet /> :   <Navigate to='/admin-login' />}
  </>)
}

export default PrivateRoute;

/////////////////////////////////////

const PrivateRouteFinancial = () => {
  
  const isAuthenticated = true
  let {role} = useContext(AuthContext)
  let {authTokens} = useContext(AuthContext)
  
return(<>
  {/* {isAuthenticated ? <Outlet /> : <Navigate to='/admin-login' />} */}
  {role === 'financial' && authTokens ?  <Outlet /> :   <Navigate to='/admin-login' />}
</>)
}

export {PrivateRouteFinancial};