import React from 'react'
import { formatPrice } from '../../../common/services/formatData';

const ProductPricesTd = ({prices}) => {
  return (
    <td>
      {prices && prices[0] &&
        prices.map((price) => ( 
          <div key={price.id}>
            {price.recurring ? "recurring-price" : formatPrice(price)}
          </div>
        ))
      }
    </td>
  )
}

export default ProductPricesTd