import React, { useState }  from 'react'
import { Table } from 'react-bootstrap'
import { Months } from '../main-pages/merchantSubscription'
import { converTime } from '../main-pages/merchantSubscription'
import Skeleton from 'react-loading-skeleton';
import PaginationNav from './PaginationNav';
import RetrySingleInvoiceModal from './modals/RetrySingleInvoiceModal';

export const getInvoiceStatusColor = (status) =>{
  switch (status) {
    case 'void':
      return '#EB455F';
    case 'paid':
      return 'green';
    case 'uncollectible':
      return 'orange';
    case 'open':
      return 'skyBlue';
    case 'draft':
      return 'gray';
    default:
      return 'black'; // Default color if no match is found
  }
}


function InvoicesSubscriptionSection(props) {

  const [ currentPage , setCurrentPage ] = useState(1)
  const [ recordsPerPage, setRecordsPerPage ] = useState(5)
  const indexOfLastRow = currentPage * recordsPerPage;
  const indexOfFirstRow = indexOfLastRow - recordsPerPage; // first index in each page 
  const recordsInvoices = props.subscriptionInvoices.slice(indexOfFirstRow, indexOfLastRow); 
  // Change page
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="admin-table-container">
        {/* {console.log('props.subscriptionInvoices', props.subscriptionInvoices)} */}

        
        {props.isInvoiceLoading && <Skeleton style={{width:'90rem'}} />}
        {!props.isInvoiceLoading && <> <table>
            <thead style={{borderBottom:'1px solid lightGray'}}>
                <tr style={{fontSize:'12px'}}>
                  <th>AMOUNT</th>
                  <th> </th>
                  <th>INVOICE NUMBER</th>
                  <th>CUSTOMER</th>
                  <th>CREATED</th>
                </tr>
            </thead>
            <tbody className="invoices-list">
                {props.subscriptionInvoices.length > 0 ? (
                recordsInvoices.map((invoice, idx) => (
                <tr key={idx} style={{fontSize:'14px'}}>
                    <td style={{fontWeight:'bold'}}>{invoice.lines.data[0].plan.metadata.currency_type === 'jod'?
                    <>{((invoice.amount_due/100)*0.7080).toFixed(2)} {invoice.lines.data[0].plan.metadata.symbol} {invoice.lines.data[0].plan.metadata.currency_type}</>
                    :
                    <>{(invoice.amount_due/100).toFixed(2)} {invoice.lines.data[0].plan.metadata.symbol} {invoice.currency}</>
                    }
                    </td>
                    <td style={{display:'flex', justifyContent:'center'}}>
                    {invoice.status === 'open' ? <>
                      <span style={{ backgroundColor: getInvoiceStatusColor(invoice.status), color:'white', borderRadius:'5px', padding:'0.2rem', fontSize:'12px', marginRight:'2px' }}>{invoice.status}</span>
                      {/* {console.log('invoice', invoice)} */}
                      <RetrySingleInvoiceModal e={invoice} merchantId={props.merchantId} customerId={props.customerId} setReloadDataInvoice={props.setReloadDataInvoice} subscriptionDetail={props.subscriptionDetail} setReload={props.setReload}/>
                      </>
                      :
                        <span style={{ backgroundColor: getInvoiceStatusColor(invoice.status), color:'white', borderRadius:'5px', padding:'0.2rem', fontSize:'12px' }}>{invoice.status}</span>
                    } </td>
                    <td>{invoice.number || 'Draft'}</td>
                    <td>{invoice.customer_email}</td>
                    <td>{converTime(invoice.created)}</td>
                </tr>
                ))   
                ) : 
                (<>
                <tr><td colSpan={3}>No invoices yet.</td></tr>
                </>)}
            
            </tbody>
            </table>

            <PaginationNav 
            recordsPerPage = {recordsPerPage}
            totalRows = {props.subscriptionInvoices.length} 
            currentPage={currentPage}
            paginate= {paginate}/>
            </>}


        
        
      
    </div>
  )
}

export default InvoicesSubscriptionSection


