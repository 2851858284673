import React, { useState } from "react";
import currencyData from "../common/files/currencies.json";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

const TotalAmountCharges = (props) => {
  const { chargesData } = props;
  const currencySymbol =
    currencyData.find((item) => item.name === props.currency.toUpperCase())
      ?.symbol || "";

  //the state of the edit input field display
  const [editableColumns, setEditableColumns] = useState({
    netVolume: false,
    grossVolume: false,
    totalFees: false,
  });

  // the state for the values
  const [editedValues, setEditedValues] = useState({
    netVolume: chargesData?.net_volume || 0,
    grossVolume: chargesData?.total || 0,
    totalFees: chargesData?.total_fees || 0,
  });

  // display the edit input for the clicked button
  const handleEdit = (columnName) => {
    setEditableColumns((prev) => ({ ...prev, [columnName]: true }));
  };

  //handle saving the edited value
  const handleSave = (columnName) => {
    setEditableColumns((prev) => ({ ...prev, [columnName]: false }));

    const data = {
      columnName,
      editedValue: editedValues[columnName],
    };

    axios
      .put("/update-charges", data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  // hide the edit input and reset the edited value to the original value
  const handleCancel = (columnName) => {
    setEditableColumns((prev) => ({ ...prev, [columnName]: false }));
    setEditedValues((prev) => ({
      ...prev,
      [columnName]: chargesData[columnName] || 0,
    }));
  };

  // handle input changes
  const handleInputChange = (columnName, event) => {
    const { value } = event.target;
    setEditedValues((prev) => ({ ...prev, [columnName]: value }));
  };

  return (
    <Container
      style={{
        padding: "30px",
        backgroundColor: "#8085e3",
        color: "white",
        borderRadius: "10px",
        marginTop: "20px",
      }}
    >
      <Row>
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Net Volume :{" "}
            {editableColumns.netVolume ? (
              <input
                className="mb-3 rounded border-0 "
                type="number"
                value={editedValues.netVolume}
                onChange={(event) => handleInputChange("netVolume", event)}
              />
            ) : chargesData && chargesData.net_volume ? (
              chargesData.net_volume.toFixed(2)
            ) : (
              "N/A"
            )}{" "}
            {currencySymbol}
            {editableColumns.netVolume ? (
              <>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleSave("netVolume")}
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleCancel("netVolume")}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-light mx-2 "
                onClick={() => handleEdit("netVolume")}
              >
                Edit
              </button>
            )}
          </p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Gross Volume :{" "}
            {editableColumns.grossVolume ? (
              <input
                className="mb-3 rounded border-0 "
                type="number"
                value={editedValues.grossVolume}
                onChange={(event) => handleInputChange("grossVolume", event)}
              />
            ) : chargesData && chargesData.total ? (
              chargesData.total.toFixed(2)
            ) : (
              "N/A"
            )}{" "}
            {currencySymbol}
            {editableColumns.grossVolume ? (
              <>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleSave("grossVolume")}
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleCancel("grossVolume")}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-light mx-2 "
                onClick={() => handleEdit("grossVolume")}
              >
                Edit
              </button>
            )}
          </p>
        </Col>
        <Col>
          <p style={{ fontWeight: "bold" }}>
            Total Fees :{" "}
            {editableColumns.totalFees ? (
              <input
                className="mb-3 rounded border-0 "
                type="number"
                value={editedValues.totalFees}
                onChange={(event) => handleInputChange("totalFees", event)}
              />
            ) : chargesData && chargesData.total_fees ? (
              chargesData.total_fees.toFixed(2)
            ) : (
              "N/A"
            )}{" "}
            {currencySymbol}
            {editableColumns.totalFees ? (
              <>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleSave("totalFees")}
                >
                  Save
                </button>
                <button
                  className="btn btn-outline-light mx-2 "
                  onClick={() => handleCancel("totalFees")}
                >
                  Cancel
                </button>
              </>
            ) : (
              <button
                className="btn btn-outline-light mx-2 "
                onClick={() => handleEdit("totalFees")}
              >
                Edit
              </button>
            )}
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TotalAmountCharges;
