import AxiosInstanceMENACartServer from "../../components/axios/AxiosInstanceMENACartServer"

export const getProducts = async () => {
  try {
    let response = await AxiosInstanceMENACartServer.post(`products`,JSON.stringify({
      is_active: true,
      merchant_id: null,
    }))
    if ( response && response.data ) {
      return response.data;
    } else {
      return null
    }
  } catch (error) {
    console.log('error :>> ', error);
    return null;
  }
}
export const archiveFintesaProduct = async (productID, setLoading) => {
  setLoading(true);
  try {
    let response = await AxiosInstanceMENACartServer.post(`/fintesa-product/archive`,JSON.stringify({
      product_id: productID,
    }))
    if ( response && response.data ) {
      console.log('response.data :>> ', response);
      return response.data;
    } else {
      return null
    }
  } catch (error) {
    console.log('error :>> ', error);
    return null;
  } finally {
    setLoading(false);
  }
}
export const createProduct = async (product, setLoading) => {
  setLoading(true);
  try {
    let response = await AxiosInstanceMENACartServer.post("/product/create-fintesa",JSON.stringify({
      name: product.name,
      description: product.description,
      prices: product.prices,
    }));
    console.log('response before :>> ', response);
    if ( response && response.data ) {
      console.log('response.data :>> ', response);
      return response.data;
    } else {
      return null
    }
  } catch (error) {
    console.log('error :>> ', error);
    return null;
  } finally {
    setLoading(false);
  }
}