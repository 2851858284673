import React, { useState , useEffect , useRef } from 'react'
import '../Dashboard.css'
// import Chart from 'chart.js';
import { Chart, CategoryScale, LinearScale, BarController, BarElement } from 'chart.js';

// Register the required plugins
Chart.register(CategoryScale, LinearScale, BarController, BarElement);

const ChartMonthlyCustomer = (props) => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const data = {
      labels: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ],
      datasets: [{
        label: 'Number of Merchants',
        // data: Object.values(props.merchantsEachMonth).slice(1),
        data: [props.merchantsEachMonth.number_of_merchants_1
          , props.merchantsEachMonth.number_of_merchants_2
          , props.merchantsEachMonth.number_of_merchants_3
          , props.merchantsEachMonth.number_of_merchants_4
          , props.merchantsEachMonth.number_of_merchants_5
          , props.merchantsEachMonth.number_of_merchants_6
          , props.merchantsEachMonth.number_of_merchants_7
          , props.merchantsEachMonth.number_of_merchants_8
          , props.merchantsEachMonth.number_of_merchants_9
          , props.merchantsEachMonth.number_of_merchants_10
          , props.merchantsEachMonth.number_of_merchants_11
          , props.merchantsEachMonth.number_of_merchants_12],
        backgroundColor: 'rgb(242,239,255)',
        borderColor: 'rgb(128,133,227)',
        borderWidth: 1
      }]
    };

    // Destroy previous chart instance
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    // Create new chart instance
    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        }
      }
    });
  }, [props.merchantsEachMonth]);

  return (
    <div
    className='yearly-chart' 
    style={{ 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    }}>
      <canvas ref={chartRef}></canvas>    
    </div>
  )
}

export default ChartMonthlyCustomer
