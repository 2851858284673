/** @format */

import axios from 'axios';
import { getCookie } from 'typescript-cookie';

const baseURL = process.env.REACT_APP_API_URL_ONBOARDING_SERVER;

let Axios = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: `Bearer ${getCookie('jwt_authtoken') ? getCookie('jwt_authtoken') : null}`,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
        },
        withCredentials: true,
    },
  });

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
  )


export default Axios;