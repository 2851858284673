import { useState } from 'react';
import { Button, Modal, Form, Spinner,Row } from 'react-bootstrap';
import PhoneNumberInput from '../PhoneNumberInput';
import { toast } from 'react-toastify';
import Axios from '../axios/AxiosOnboarding';


const AddAccountModal = (props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [position, setPosition] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [role, setRole ] = useState('');
    const [isActive, setIsActive ] = useState(false);

    const [loading , setloading] = useState(false)

    const handleCreate = (e) => {
        setloading(true)
        e.preventDefault();
        // onSubmit()
        const member = {
            first_legal_name: firstName,
            last_legal_name: lastName,
            email: email,
            phone_number: phoneNumber,
            role: role,
            is_active: isActive,
            position: position
          };
        Axios  // the route not ready
        .post(`/create-account`, 
        member)
        .then((res)=>{
            // console.log(res.data);
            props.setReload( current => !current)
            toast.success('Data Created')
        }).catch((err)=>{
            // console.log(err.message)
            toast.error('Something went wrong')
        }).finally( () => {
          setloading(false)
          handleClose()
        });
    }

  return (
    <div>
      <Button variant="light" style={{ color: "white",
                backgroundColor: "rgb(98,77,227)",
                border: "none",
                fontWeight: "bold", }} onClick={handleShow}>
      + Add Account
      </Button>

      <Modal show={show} onHide={handleClose} size="lg" style={{marginTop:'8rem'}}>
        <Modal.Header closeButton>
          <Modal.Title style={{  fontSize:'18px', color: 'rgb(98,77,227)' }}>Add Team Account</Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <Form onSubmit={handleCreate} >
            <Row style={{width:'70%',marginInline:'5rem'}}>
                <Form.Group controlId="status" className='mb-3'>
                    <Form.Label>Role</Form.Label>
                    <Form.Control
                    as="select" 
                    value={role}
                    onChange={(e) => setRole(e.target.value)} 
                    
                    >
                    <option value="">Select Role</option>
                    <option value="User">User</option>
                    <option value="Admin">Admin</option>
                    <option value="financial">financial</option>
                   </Form.Control>
                </Form.Group>

                <Form.Group controlId="firstName" className='mb-3'>
                <Form.Control
                type="text"
                value={firstName}
                placeholder='First Name'
                onChange={(e) => setFirstName(e.target.value)}
                />
                </Form.Group>

                <Form.Group controlId="lastName" className='mb-3'>
                <Form.Control
                type="text"
                value={lastName}
                placeholder='Last Name'
                onChange={(e) => setLastName(e.target.value)}
                />
                </Form.Group>

                <Form.Group controlId="email" className='mb-3'>
                    <Form.Control
                    type="email"
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />
                </Form.Group>

                <Form.Group controlId="position" className='mb-3'>
                <Form.Control
                type="text"
                value={position}
                placeholder='Position'
                onChange={(e) => setPosition(e.target.value)}
                />
                </Form.Group>

                <Form.Group style={{display:'flex' , justifyContent:'space-evenly' }} controlId="isActive" className='mb-3'>
                <Form.Label>Is Active</Form.Label>
                <Form.Check
                type="checkbox"
                checked={isActive}
                onChange={(e) => setIsActive(e.target.checked)}
                />
                </Form.Group>

                <Form.Group controlId="phoneNumber" className='mb-3'>
                  <PhoneNumberInput 
                  phoneNumber={phoneNumber}
                  setPhoneNumber={setPhoneNumber}/>

                </Form.Group>
                </Row>
            </Form>
        </Modal.Body>

        <Modal.Footer style={{justifyContent:'center',justifyItems:'center'}}>
          <Button type="submit" style={{ color: 'white', backgroundColor:'rgb(98,77,227)', border:'none', width:'80%'}} 
          onClick={handleCreate} disabled={loading}
          >
           {loading ? <Spinner animation='border' variant='light' size='sm'/> : <> Create</>}
          </Button>
        </Modal.Footer>
      </Modal>
      
    </div>
  )
}

export default AddAccountModal;
