import { createContext, useState, useEffect } from 'react'
import jwt_decode from "jwt-decode";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getCookie, setCookie, removeCookie } from 'typescript-cookie';
import Axios from '../components/axios/AxiosOnboarding';

const AuthContext = createContext()

export default AuthContext

export const AuthProvider  = ({children}) => {

    let [authTokens, setAuthTokens] = useState(() =>  getCookie('jwt_authtoken') ? (getCookie('jwt_authtoken')) : null);

    let [user, setUser] = useState(() =>  getCookie('email') ? JSON.parse(getCookie('email')) : {});
    let [role, setrole] = useState(() =>  getCookie('role') ? JSON.parse(getCookie('role')) : "");

    const [loading , setloading] = useState(false)

    useEffect(() => {
      if( authTokens && user ){
         setCookie('jwt_authtoken', (authTokens), 1);
         setCookie('email', JSON.stringify(user), 1);
         setCookie('role', JSON.stringify(role), 1);
      }
    }, [authTokens, user]);
   

    const navigate = useNavigate()

    let loginUser = async (email) => {
      setloading(true)
    try {
      const response = await Axios.post(
        `/login`,
        {
          email: email,
        }
      );

      setAuthTokens(response.data.access_token);
      // console.log(JSON.stringify(response.data.user_info["email"].replaceAll('%22','')))
      // console.log(typeof response.data.access_token)
      setUser(response.data.user_info);
      setrole(response.data.user_info["role"])
      setCookie('jwt_authtoken', `Bearer ${(response.data.access_token.replaceAll('%22',''))}`);
      setCookie('email', JSON.stringify(response.data.user_info["email"].replaceAll('%22','')), 1);
      setCookie('role', JSON.stringify(response.data.user_info["role"].replaceAll('%22','')), 1);
      
    } catch (error) {
      console.error('Error login:', error);
      if (error.response) {
        // Server responded with a non-2xx status code
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      } else if (error.request) {
        // The request was made but no response was received
        console.error('Request:', error.request);
      } else {
        // Something happened in setting up the request that triggered an error
        console.error('Error:', error.message);
      }
      
    } finally {
      if (role=="financial"){
        navigate('/sms');
        toast.success('Login Successfuly')
        setTimeout(() => {
          window.location.reload(true)
        }, 1000);
      } else {
        navigate('/');
        toast.success('Login Successfuly')
        setTimeout(() => {
          window.location.reload(true)
        }, 1000);
      }
      }
  }

  let logoutUser = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${getCookie('jwt_authtoken')}`,
      },
    }

    try {
      let response= await Axios.post(`/logout`)
        removeCookie('jwt_authtoken');
        removeCookie('email');
        removeCookie('role');
        navigate('/admin-login');
        setTimeout(() => {
          window.location.reload(true)
        }, 1000);
    } catch (error) {
      console.log(error);
    } 
  }

  let contextData = {
    authTokens : authTokens,
    user : user,  // contains user information
    role :role,
    loginUser : loginUser,  // should pass this data inside login form
    logoutUser : logoutUser,

  }


  return (
    <AuthContext.Provider value={contextData} >
        {children}
    </AuthContext.Provider>
  )
}



