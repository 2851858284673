import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import ProductPricesTd from './ProductPricesTd'
import { getDateFromTimestamp } from '../../../common/services/formatData'
import ProductPaymentLinksTd from './ProductPaymentLinksTd'
import ArchiveProductButton from './ArchiveProductButton'
const ProductsTable = ({data, setData}) => {
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  return (
    <div style={{overflowX: "auto"}}>
      <Table hover>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Created</th>
            <th>Updated</th>
            <th>Payment Link</th>
            <th>Actions</th>
          </tr>
        </thead>
        {data && data[0] &&
          <tbody>
            {data.map((product) => (
              <tr key={product.id}>
                <td>
                  {product.name}
                </td>
                <ProductPricesTd prices={product.prices} />
                <td>
                  {getDateFromTimestamp(product.created)}
                </td>
                <td>
                  {getDateFromTimestamp(product.updated)}
                </td>
                <ProductPaymentLinksTd setData={setData} prices={product.prices} productID={product.id} buttonsDisabled={buttonsDisabled} setButtonsDisabled={setButtonsDisabled} />
                <td>
                  <ArchiveProductButton setData={setData} isActive={product.active} productID={product.id} buttonsDisabled={buttonsDisabled} setButtonsDisabled={setButtonsDisabled} />
                </td>
              </tr>
            ))}
          </tbody>
        }
      </Table>
    </div>
  )
}

export default ProductsTable