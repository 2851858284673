import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { archiveFintesaProduct } from "../../../common/services/products";

const ArchiveProductButton = ({ isActive, productID, buttonsDisabled, setButtonsDisabled, setData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const archiveProduct = async () => {
    let responseData = await archiveFintesaProduct(productID, setLoadingStates);
    if (responseData) {
      setData((previousData) => previousData.map((product) => product.id === responseData.id ? {...responseData} : product));
    }
  };
  const setLoadingStates = (value) => {
    setIsLoading(value);
    setButtonsDisabled(value);
  }
  return (
    <Button
      onClick={isActive ? archiveProduct: () => {} }
      disabled={!isActive || buttonsDisabled}
    >
      {isLoading? <Spinner size="sm" animation="border" /> :isActive ? "Archive" : "Archived"}
    </Button>
  );
};

export default ArchiveProductButton;
